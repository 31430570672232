import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from "luxon";

@Pipe({
	name: 'dateTimezone'
})
export class DateTimezonePipe implements PipeTransform {
	transform(value: any, format: string): any {
		const dateLocalized = DateTime.fromISO(value, {zone: 'UTC'}).toLocal();
		if(dateLocalized.isValid){
			return dateLocalized.toFormat(format);
		}
		return value;
	}
}
