<div data-theme="default" data-layout="fluid" data-sidebar-position="left" data-sidebar-behavior="sticky">
	<div class="main d-flex justify-content-center w-100">
		<main class="content d-flex p-0">
			<div class="container d-flex flex-column">
				<div class="row h-100">
					<div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
						<div class="d-table-cell align-middle">
							<div class="text-center">
								<img class="mb-4" width="180" src="assets/images/cts-blue-vert.png" alt="">
							</div>
							<router-outlet></router-outlet>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</div>
