import * as _ from 'lodash';
import {DateOptionValue, FIELD_TYPES, FilterField} from './filters';
import {RemediationReportModel} from './remediation-report';
import {FileItem} from './file';
import {LocalizedDatePipe} from '../modules/shared/localized-date.pipe';
import {InsuranceCompanyModel} from "./insurance-company";

export interface ValidationControls {
	[key: string]: {
		formControls?: string[],
		show: boolean,
		index: number
	};
}

export enum SpillReportStatus {
	INCOMPLETE = 1,
	REPORTED = 2,
	COMPLETE = 3,
	VERIFIED = 4
}

export enum SpillReportLabel {
	INCOMPLETE = 'Incomplete',
	REPORTED = 'Reported',
	COMPLETE = 'Complete',
	VERIFIED = 'Verified'
}

export enum AddressMode {
	MAP_MODE = 1,
	MANUAL_COORDINATES = 2,
	ADDRESS_MODE = 3
}

export enum ReportType {
	TOW_REPORT = 'tow_report',
	POLICE_REPORT = 'police_report'
}

export enum CallType {
	ACCIDENT = 'accident',
	NON_ACCIDENT = 'non_accident'
}

export enum LegacyImageTags {
	COLLISION = 'collussion',
	HAZARDOUS_FLUIDS = 'hazardous_fluids',
	DRIVERS_LICENSE = 'driver_licence',
	INSURANCE_CARD = 'insurance_card',
}

export enum ImageTags {
	SPILL_REPORT_LOCATION = 'spill_report_location',
	REMEDIATION_REPORT_LOCATION = 'remediation_report_location',
	BEFORE_REMEDIATION = 'before_remediation',
	REMEDIATION_APPLICATION = 'remediation_application',
	AFTER_REMEDIATION_FINAL = 'after_remediation_final',
	SCENE_PICTURE = 'scene_picture',
}

export enum ImageTagsLabel {
	SPILL_REPORT_LOCATION = 'Spill Report Location',
	REMEDIATION_REPORT_LOCATION = 'Remediation Report Location',
	BEFORE_REMEDIATION = 'Before Remediation',
	REMEDIATION_APPLICATION = 'Remediation Application',
	AFTER_REMEDIATION_FINAL = 'After Remediation Final',
	SCENE_PICTURE = 'Scene Picture'
}

export const callTypeOptions = [
	{label: 'Accident', value: CallType.ACCIDENT},
	{label: 'Non Accident', value: CallType.NON_ACCIDENT}
];

export const SpillReportStatusOptions = [
	{ label: SpillReportLabel.INCOMPLETE, value: SpillReportStatus.INCOMPLETE},
	{ label: SpillReportLabel.REPORTED, value: SpillReportStatus.REPORTED},
	{ label: SpillReportLabel.COMPLETE, value: SpillReportStatus.COMPLETE},
	{ label: SpillReportLabel.VERIFIED, value: SpillReportStatus.VERIFIED}
];

export const SpillReportTypeOptions = [
	{ label: 'Tow report', value: ReportType.TOW_REPORT},
	{ label: 'Police report', value: ReportType.POLICE_REPORT},
]

export const reportValueOptions = [
	{label: 'Incomplete', value: SpillReportStatus.INCOMPLETE},
	{label: 'Reported', value: SpillReportStatus.REPORTED},
	{label: 'Complete', value: SpillReportStatus.COMPLETE},
	{label: 'Verified', value: SpillReportStatus.VERIFIED},
	{label: 'Confirm', value: null},
];

export const SPILL_FILTER_FIELDS: FilterField[] = [
	{label: 'Companies', property: 'companies', type: FIELD_TYPES.TEXT, isPublic: true},
	{label: 'Spill Status', property: 'incident_status', type: FIELD_TYPES.TEXT, isPublic: true, options: SpillReportStatusOptions},
	{label: 'State', property: 'incident_state', type: FIELD_TYPES.TEXT, isPublic: true},
	{label: 'Tow Driver', property: 'driver', type: FIELD_TYPES.TEXT, isPublic: true},
	{label: 'Cleaner', property: 'cleaner', type: FIELD_TYPES.TEXT, isPublic: true},
];

export const SPILL_SEARCH_FIELDS: FilterField[] = [
	{label: 'Incident ID', property: 'incident_number', type: FIELD_TYPES.NUMBER, isPublic: true},
	{label: 'Spill ID', property: 'spillId', type: FIELD_TYPES.NUMBER, isPublic: true},
];

export const SPILL_DATE_OPTIONS: DateOptionValue[] = [
	{
		label: 'Incident Date',
		value: 'incidentDate',
		isPublic: true
	}
];

export const SPILL_REPORT_VALIDATION_CONTROLS: ValidationControls = {
	locationInfo: {
		formControls: ['latitude', 'longitude', 'street_name', 'city', 'state', 'cross_street_name', 'distance', 'additional_notes'],
		show: true,
		index: 0
	},
	incidentInfo: {
		formControls: ['incident_date', 'incident_time', 'incident_city', 'incident_state', 'vehicle_involved_in_MVA', 'vehicle_leaking_O_fluids', 'police_report_link'],
		show: false,
		index: 1
	},
	driverInfo: {
		formControls: ['driver_fname', 'driver_lname', 'driver_street_add', 'driver_city', 'driver_state'],
		show: false,
		index: 2
	},
	insuranceInfo: {
		formControls: ['policy_owner', 'policy_owner_lname', 'policy_owner_street', 'policy_owner_city', 'policy_owner_state', 'insurance_company',
			'insurance_policy', 'insurance_claim_number'],
		show: false,
		index: 3
	},
	vehicleInfo: {
		formControls: ['vin', 'vehicle_year', 'vehicle_make', 'vehicle_model'],
		show: false,
		index: 4
	}
};

export const driverInfoToInsuranceInfoMap: Map<string, string> = new Map([
	['driver_fname', 'policy_owner'],
	['driver_lname', 'policy_owner_lname'],
	['driver_street_add', 'policy_owner_street'],
	['driver_city', 'policy_owner_city'],
	['driver_state', 'policy_owner_state']
]);

export const insuranceInfoToDriverInfoMap = new Map(Array.from(driverInfoToInsuranceInfoMap, entry => [entry[1], entry[0]]));

export function getDriverOrInsuranceMap(copyFrom: string): Map<string, string> {
	if(copyFrom === 'driverInfo'){
		return driverInfoToInsuranceInfoMap;
	}
	return insuranceInfoToDriverInfoMap;
}

export class SpillReportModel {
	additional_notes: string;
	address_mode: string;
	call_type: CallType;
	city: string;
	created_at: string;
	cross_street_info: string;
	cross_street_name: string;
	distance: string;
	driver_city: string;
	driver_fname: string;
	driver_lname: string;
	driver_state: string;
	driver_street_add: string;
	incident_city: string;
	incident_state: string;
	incident_status: SpillReportStatus;
	//use individuals for ui 
	incident_date: string;
	incident_time: string;
	//this should be auto-computed in backend and not modified on frontend
	incident_datetime_utc: string;
	incident_timezone: string;
	incident_number: string;
	insurance_claim_number: string;
	insurance_company: string;
	insurance_policy: string;
	insurance_status: string;
	remediation_report: RemediationReportModel;
	latitude: number;
	longitude: number;
	map_string: string;
	matric_option: string;
	police_report_link: string;
	policy_owner: string;
	policy_owner_lname: string;
	policy_owner_street: string;
	policy_owner_city: string;
	policy_owner_state: string;
	report_type: string;
	spill_report_id: number;
	state: string;
	street_name: string;
	vehicle_VIN: string;
	vehicle_involved_in_MVA: number;
	vehicle_leaking_O_fluids: number;
	vehicle_make: string;
	vehicle_model: string;
	vehicle_year: string;

	collussion: FileItem[];
    hazardous_fluids: FileItem[];
    driver_licence: FileItem[];
    insurance_card: FileItem[];
    spill_report_location: FileItem[];
	localizedDate: LocalizedDatePipe;

	insurance_company_entity: InsuranceCompanyModel;


	constructor(report: SpillReportModel) {
		_.assign(this, report);
		this.localizedDate = new LocalizedDatePipe();
		this.incident_date = `${(this.localizedDate.transform(this.incident_datetime_utc, this.incident_timezone, 'yyyy-LL-dd'))}`;
		this.incident_time = `${(this.localizedDate.transform(this.incident_datetime_utc, this.incident_timezone, 'HH:mm:ss'))}`;
	}

	public addressModeToLabel(): string{
		const addressMode = parseInt(this.address_mode);
		if (addressMode === AddressMode.MAP_MODE){
			return 'Map Mode';
		}
		if (addressMode === AddressMode.MANUAL_COORDINATES){
			return 'Manual Coordinates';
		}
		if (addressMode === AddressMode.ADDRESS_MODE){
			return 'Address Mode';
		}
		return '';
	}
}

export function getSpillReportStatus(status: SpillReportStatus, style: boolean = false): string{
	if (status === SpillReportStatus.INCOMPLETE) {
		return style ? 'text-danger' : 'Incomplete';
	}
	if (status === SpillReportStatus.REPORTED){
		return style ? 'text-warning' : 'Reported';
	}
	if (status === SpillReportStatus.COMPLETE){
		return style ? 'text-info' : 'Complete';
	}
	if (status === SpillReportStatus.VERIFIED){
		return style ? 'text-success' : 'Verified';
	}
	return '';
}
