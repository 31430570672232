import {Component, OnInit} from '@angular/core';
import {NavService} from 'src/app/services/nav.service';
import {sidebarMenuAnimation} from '../../../helpers/animation';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	animations: [sidebarMenuAnimation]
})
export class DashboardComponent implements OnInit {
	loading = false;

	constructor(public navService: NavService) {
	}

	ngOnInit() {

	}

	mobileNavToggle(){
		this.navService.toggleCollapse();
	}

}
