import { Pipe, PipeTransform } from '@angular/core';
import {DateTime} from "luxon";

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  transform(datetimeUTC: string, timezone: string, format: string): unknown {
	  const dateLocalized = DateTime.fromISO(datetimeUTC, {zone: 'UTC'}).setZone(timezone);
    return dateLocalized.toFormat(format);
  }

}
