import {animate, state, style, transition, trigger} from "@angular/animations";

const ANIMATION_TIME = '0.3s'

export const sidebarMenuAnimation = [

    trigger('menuPopout', [
        transition(':enter', [
            style({transform: 'translateX(-100%)'}),
            animate('0.5s ease', style({transform: 'translateX(0)'}))
        ]),
        transition(':leave', [
            style({transform: 'translateX(0)'}),
            animate('0.5s ease', style({transform: 'translateX(-100%)'}))
        ])
    ]),
    trigger('menuOverlay', [
        transition(':enter', [
            style({opacity: 0}),
            animate('0.5s ease', style({opacity: 1}))
        ]),
        transition(':leave', [
            style({opacity: 1}),
            animate('0.5s ease', style({opacity: 0}))
        ])
    ])
];


export const filterMenuAnimations = [
    trigger('popOverState', [
        state('show', style({
            transform: 'translateX(0)'
        })),
        state('hide', style({
            transform: 'translateX(-100%)'
        }))
    ]),

    trigger('collapseState', [
        state('expanded', style({
            width: '13.5rem'
        })),
        state('collapsed', style({
            width: '5.2rem'
        }))
    ]),
];

export const listAnimations = [
    trigger('animatedListItem', [
        transition(':enter', [
            style({transform: 'translateY(-50%)', opacity: 0}), // initial
            animate(`${ANIMATION_TIME} ease`, style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
            style({transform: 'translateY(0)', opacity: 1}), // initial
            animate(`${ANIMATION_TIME} ease`, style({transform: 'translateY(-50%)', opacity: 0}))
        ])
    ]),
];
