import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { QRScanComponent } from "./qr-scan/qr-scan.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { SharedModule } from "./modules/shared/shared.module";
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
	declarations: [AppComponent, QRScanComponent],
	imports: [
		FormsModule,
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		PasswordStrengthMeterModule,
		ToastrModule.forRoot({
			positionClass: "toast-bottom-right",
			toastClass: "toast-container ngx-toastr custom-toast",
			timeOut: 6500,
		}),
		NgbModule,
		SharedModule,
		NgxDropzoneModule,
		// GooglePlaceModule,
	],
	providers: [
		// {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		// {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true}
	],

	bootstrap: [AppComponent],
})
export class AppModule {}
