import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-warning-modal',
    templateUrl: './warning-modal.component.html',
    styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {
    @Input() negativeText: string = 'Cancel';
    @Input() positiveText: string = 'Okay';
    @Input() message: string = 'Example warning message';
    @Input() title: string | null = null;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }


    onNegativeAction() {
        this.activeModal.dismiss(false);
    }

    onPositiveAction() {
        this.activeModal.close(true);
    }
}
