import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedOutShellComponent } from "./modules/logged-out-shell/logged-out-shell.component";
import { DashboardComponent } from "./modules/dashboard/dashboard/dashboard.component";
import { AuthGuard } from "./guards/auth.guard";
import { QRScanComponent } from "./qr-scan/qr-scan.component";

const routes: Routes = [
	{
		path: "",
		component: DashboardComponent,
		canActivate: [AuthGuard],
		loadChildren: () =>
			import("./modules/dashboard/dashboard.module").then(
				(m) => m.DashboardModule
			),
	},
	{
		path: "",
		component: LoggedOutShellComponent,
		loadChildren: () =>
			import("./modules/logged-out-shell/logged-out-shell.module").then(
				(m) => m.LoggedOutShellModule
			),
	},
	{ path: "invoice-report", component: QRScanComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
