import {TypeaheadAdapter} from './index';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {inject, InjectionToken} from '@angular/core';
import {User} from '../../models/user';
import {UserService} from '../user.service';
import {Roles} from '../../helpers/permissions';

export class UserByRoleTypeaheadAdapter implements TypeaheadAdapter<User> {
	focus$ = new Subject<string>();
	users: User[] = [];
	role: Roles;

	constructor(private userService: UserService, role: Roles) {
		this.role = role;
	}

	inputFormatter(input: User): string {
		return `${input.firstName} ${input.lastName}`;
	}

	resultFormatter(result: User): string {
		return `${result.firstName} ${result.lastName}`;
	}

	search = (text$: Observable<string>): Observable<User[]> => {
		const users$ = this.userService.getAll(this.role).pipe(
			tap(c => this.users = c),
			shareReplay()
		);
		const debouncedText$ = text$.pipe(debounceTime(350), distinctUntilChanged());

		return merge(debouncedText$, this.focus$).pipe(
			switchMap((search) => users$.pipe(
				map((companies: User[]) => {
					const lowerTxt = search.toLowerCase();
					return lowerTxt.length == 0 ? companies.slice(0, 10)
						: companies.filter(v => (v.firstName + v.lastName).toLowerCase().indexOf(lowerTxt) > -1).slice(0, 10);
				})
			)),
		);
	}
}

export const COMPANY_ADMIN_SEARCH = new InjectionToken<UserByRoleTypeaheadAdapter>('company admin search typeahead', {
	providedIn: 'root',
	factory: () => {
		const userService = inject(UserService);
		return new UserByRoleTypeaheadAdapter(userService, Roles.COMPANY_ADMIN);
	}
});

export const CLEANER_SEARCH = new InjectionToken<UserByRoleTypeaheadAdapter>('cleaner search typeahead', {
	providedIn: 'root',
	factory: () => {
		const userService = inject(UserService);
		return new UserByRoleTypeaheadAdapter(userService, Roles.CLEANER);
	}
});
