import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';


export class BaseUserForm {
	firstName: FormControl;
	lastName: FormControl;
	email: FormControl;
	phone: FormControl;

	constructor(formBuilder: FormBuilder) {
		formBuilder.group({
			firstName: ['', Validators.required],
			lastName: [''],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			phone: [''],
		});
	}
}

export class AddEditUserForm {
	firstName: FormControl;
	lastName: FormControl;
	email: FormControl;
	phone: FormControl;
	role: FormControl;
}

export function validationState(control: AbstractControl): object {
	const stateClasses = {};
	if (control?.invalid && control?.touched) {
		stateClasses['is-invalid'] = true;
	}
	return stateClasses;
}
