<app-alert *ngIf="error" [alertType]="'alert-danger'" [message]="error?.message" [alertToggle]="error"></app-alert>
<form [formGroup]=form class="signup-form flex-fill py-4">
    <div style="visibility:hidden;display: none;" class="form-group">
        <input autocomplete="email" type="text" name="email" class="form-control">
    </div>
    <div class="form-group" *ngIf=requireCurrent>
        <!-- <label for="passwordCurrent" [class.error-label]="isInvalid('passwordCurrent')">Current password</label> -->
        <input autocomplete="current-password" formControlName="passwordCurrent" type="password" name="passwordCurrent"
               id="passwordCurrent" class="form-control" placeholder="Current password"
               [class.error-edge]="isInvalid('passwordCurrent')">
    </div>
    <div class="form-group">
        <!-- <label for="passwordNew" [class.error-label]="isInvalid('passwordNew')">New password</label> -->
        <input autocomplete="new-password" formControlName="passwordNew" type="password" name="passwordNew"
               id="passwordNew" class="form-control form-control-lg" placeholder="New password"
               [class.error-edge]="isInvalid('passwordNew')">
    </div>
    <div class="form-group mt-3">
        <!-- <label for="passwordVerify" [class.error-label]="isInvalid('passwordVerify')">Verify new password</label> -->
        <input autocomplete="new-password" formControlName="passwordVerify" type="password" name="passwordVerify"
               id="passwordVerify" class="form-control form-control-lg" placeholder="Verify new password"
               [class.error-edge]="isInvalid('passwordVerify', true)">
        <div class="mt-4">
            <password-strength-meter [password]="controls.passwordNew.value"></password-strength-meter>
        </div>
    </div>
	<div class="text-center mt-4 pt-2">
		<button class="btn btn-lg btn-primary" (click)="handleSubmit()">Change password</button>
	</div>
</form>
