import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {
	@Input() iconClass;
	@Input() resource;
	@Input() showSubtitle = true;

	constructor() {
	}

	ngOnInit(): void {
	}

}
