<nav aria-label="Page navigation" *ngIf="total > 0" class="my-4" [formGroup]="form">
	<div class="position-relative">
		<ul class="pagination justify-content-center">
			<li class="page-item" [ngClass]="{'disabled': isFirstPage}">
				<a class="page-link" routerLink="./" [queryParams]="{page: 0}" queryParamsHandling="merge">
					<i class="fas fa-chevron-double-left"></i>
				</a>
			</li>
			<li class="page-item" [ngClass]="{'disabled': isFirstPage}">
				<a class="page-link" routerLink="./" [queryParams]="{page: currentPage - 1}" queryParamsHandling="merge">
					<i class="fas fa-chevron-left"></i>
				</a>
			</li>
			<li class="page-item" *ngFor="let page of pages" [ngClass]="{'active': page === currentPage}">
				<ng-container *ngIf="fitsAroundCurrPage(page)">
					<a class="page-link" routerLink="./" [queryParams]="{page: page}"
					   queryParamsHandling="merge">{{page + 1}}</a>
				</ng-container>
			</li>
			<li class="page-item" [ngClass]="{'disabled': isLastPage}">
				<a class="page-link" routerLink="./" [queryParams]="{page: currentPage + 1}" queryParamsHandling="merge">
					<i class="fas fa-chevron-right"></i>
				</a>
			</li>
			<li class="page-item" [ngClass]="{'disabled': isLastPage}">
				<a class="page-link" routerLink="./" [queryParams]="{page: this.totalPages - 1}" queryParamsHandling="merge">
					<i class="fas fa-chevron-double-right"></i>
				</a>
			</li>
		</ul>
		<div class="d-none d-lg-block d-xl-block position-absolute top-0 end-0">
			<div class="d-flex">
				<input id="pagination_num" type="number" class="form-control pagination-number-input"
					   formControlName="pageNum"
					   min="1"
					   max="{{totalPages}}"
					   [(ngModel)]="paginationNum"
					   (keyup.enter)="goToPage({page: paginationNum - 1})"
				>
				<button class="btn btn-outline-primary text-uppercase" (click)="goToPage({page: paginationNum - 1})">
					Go To Page
				</button>
			</div>
		</div>
	</div>

	<div class="float-right" *ngIf="editPageLimit">
		<label for="pageSize" class="me-2">Results per page </label>
		<select id="pageSize" class="custom-select" formControlName="pageSize" (change)="onPageSizeChange($event)">
			<option *ngFor="let option of pageSizes" [value]="option">{{option}}</option>
		</select>
	</div>
</nav>
