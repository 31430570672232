import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    @Input() title: string;
    @Input() ctaText: string;
    @Input() ctaIco?: string = null;
    @Input() secCtaText?: string = null;
    @Input() secCtaIco?: string = null;
    @Input() permission?: string;
    @Output() ctaClicked = new EventEmitter<void>();
    @Output() secCtaClicked?: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
