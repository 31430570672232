import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RemediationReportModel } from "../models/remediation-report";
import { map } from "rxjs/operators";
import { ListDto } from "../models/list-dto";
import { Filter } from "../models/filters";
import { FileItem, UploadFileRequestDto } from "../models/file";

const REMEDIATION_REPORT_ROUTE = "/api/v1/remediationReport";
const REMEDIATION_REPORT_QRSCAN_ROUTE =
	"/api/v1/remediationReport/invoice_details";
const REMEDIATION_REPORT_INVOICE = (invoiceId) =>
	`${REMEDIATION_REPORT_QRSCAN_ROUTE}/${invoiceId}`;
const REMEDIATION_REPORT_ID = (id) => `${REMEDIATION_REPORT_ROUTE}/${id}`;
const REMEDIATION_REPORT_ALL = `${REMEDIATION_REPORT_ROUTE}/web`;
const REMEDIATION_REPORT_CSV = `${REMEDIATION_REPORT_ROUTE}/csv`;
export const REMEDIATION_REPORT_IMAGE = (reportId) =>
	`${REMEDIATION_REPORT_ID(reportId)}/photos`;

@Injectable({ providedIn: "root" })
export class RemediationReportService {
	constructor(private http: HttpClient) {}

	getAll(filters?: Filter): Observable<ListDto<RemediationReportModel>> {
		const options = filters ? { params: filters } : {};
		return this.http
			.get<ListDto<RemediationReportModel>>(REMEDIATION_REPORT_ALL, options)
			.pipe(
				map((res) => {
					return {
						data: res.data.map((v) => new RemediationReportModel(v)),
						meta: res.meta,
					};
				})
			);
	}

	getByReportId(id: number): Observable<RemediationReportModel> {
		return this.http
			.get<RemediationReportModel>(REMEDIATION_REPORT_ID(id))
			.pipe(map((report) => new RemediationReportModel(report)));
	}

	update(id: number, params: Partial<RemediationReportModel>) {
		return this.http
			.put<RemediationReportModel>(REMEDIATION_REPORT_ID(id), params)
			.pipe(map((report) => new RemediationReportModel(report)));
	}

	uploadImage(params: UploadFileRequestDto): Observable<FileItem> {
		const formData = new FormData();
		formData.append("file", params.file);
		const headers = new HttpHeaders({ "x-photo-tag": params.fileTag });
		return this.http.post<FileItem>(
			REMEDIATION_REPORT_IMAGE(params.reportId),
			formData,
			{
				reportProgress: true,
				headers,
			}
		);
	}

	deleteImage(reportId: number, image: FileItem): Observable<void> {
		return this.http.delete<void>(REMEDIATION_REPORT_IMAGE(reportId), {
			body: image,
		});
	}

	generateCsv(isQa: boolean, filters?: Filter): Observable<Blob> {
		return this.http.get(REMEDIATION_REPORT_CSV, {
			responseType: "blob",
			params: {
				...filters,
				isQa: isQa,
			},
		});
	}

	getRemediationInvoiceByReportId(
		invoiceId: number
	): Observable<RemediationReportModel> {
		return this.http
			.get<RemediationReportModel>(REMEDIATION_REPORT_INVOICE(invoiceId))
			.pipe(map((report) => new RemediationReportModel(report)));
	}
}
