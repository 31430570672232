import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-logged-out-shell',
    templateUrl: './logged-out-shell.component.html',
    styleUrls: ['./logged-out-shell.component.scss']
})
export class LoggedOutShellComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
