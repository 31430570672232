import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable, timer} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Roles} from '../helpers/permissions';
import {AppRoutes} from '../helpers/app-routes';
import {Filter} from '../models/filters';

const USER_ROUTE = '/api/v1/users';
const USER_UPDATE_PASSWORD = `${USER_ROUTE}/update-user-password`;
const USERS_INVITATION = `${USER_ROUTE}/invitation`;
const USER_ME = `${USER_ROUTE}/me`;
const USER_ALL = (role: Roles) => `/api/v1/users/all/${role}`;
const USER_ID = (id) => `${USER_ROUTE}/${id}`;
const USER_INVITE = `${USER_ROUTE}/invite`;

@Injectable({providedIn: 'root'})

export class UserService {
	static userRefreshInterval = moment.duration(15, 'minutes').asMilliseconds();

	userObservable: Observable<User> = new Observable<User>();

	constructor(private http: HttpClient) {
		this.getCurrentUserAsync(true);
	}

	getCurrentUserAsync(forceRefresh: boolean = false): Observable<User> {
		if (this.getCurrentUser() && !this.userObservable || forceRefresh) {
			this.userObservable = this.refreshUserAsync();
		}
		return this.userObservable;
	}

	refreshUserAsync(): Observable<User> {
		return timer(0, UserService.userRefreshInterval).pipe(
			switchMap(() => this.http.get<User>(USER_ME)),
			map(user => new User(user)),
			tap(user => this.updateCurrentUser(user)),
			shareReplay()
		);
	}

	private updateCurrentUser(user: User) {
		const currentUser = this.getCurrentUser();
		if (currentUser.email === user.email) {
			_.assign(currentUser, user); // merge objects to retain token property
			localStorage.setItem('currentUser', JSON.stringify(currentUser));
		}
	}

	getCurrentUser(): User {
		const payload = JSON.parse(localStorage.getItem('currentUser'));
		return payload ? new User(payload) : null;
	}

	getAll(role: Roles, filters?: Filter): Observable<User[]> {
		const options = filters ? {params: filters} : {};
		return this.http.get<User[]>(USER_ALL(role), options);
	}

	getUser(userId: number): Observable<User> {
		return this.http.get<any>(USER_ID(userId)).pipe(
			map(user => new User(user))
		);
	}

	create(params: Partial<User>): Observable<User> {
		return this.http.post<User>(USER_ROUTE, params);
	}

	deleteUser(userId: number) {
		return this.http.delete<any>(USER_ID(userId)).pipe(
			map(user => new User(user)));
	}

	updateUser(id: number, params: Partial<User>){
		return this.http.put<User>(USER_ID(id), params).pipe(
			map(user => new User(user))
		);
	}

	updateUserPassword(payload) {
		return this.http
			.post<any>(USER_UPDATE_PASSWORD, payload)
			.pipe(
				map(user => user),
			);
	}

	inviteUser(userInfo: { firstName: any; lastName: any; email: any }) {
		return this.http
			.post<any>(USER_INVITE, userInfo)
			.pipe(map(user => user));
	}

	getInvitation(invitationToken: string) {
		return this.http
			.get<any>(`${USERS_INVITATION}?invitation_token=${invitationToken}`)
			.pipe(map(invitation => invitation));
	}

	routeToRole(path: string): Roles{
		if (path === AppRoutes.Drivers.LIST){
			return Roles.DRIVER;
		}
		   if (path === AppRoutes.Cleaners.LIST){
			return Roles.CLEANER;
		}
		   if (path === AppRoutes.Admins.LIST){
			return Roles.ADMIN;
		}
		if (path === AppRoutes.SuperAdmins.LIST){
			return Roles.SUPER_ADMIN;
		}
		if (path === AppRoutes.BillingAdmins.LIST){
			return Roles.BILLING_ADMIN;
		}
		if (path === AppRoutes.CompanyAdmins.LIST){
			return Roles.COMPANY_ADMIN;
		}
		if (path === AppRoutes.CollectionAdmins.LIST){
			return Roles.COLLECTION_ADMIN;
		}

	}

}
