import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { SpillReportModel } from "../models/spill-report";
import { Observable } from "rxjs";
import { Filter } from "../models/filters";
import { ListDto } from "../models/list-dto";
import { FileItem, FileModel, UploadFileRequestDto } from "../models/file";
import { BulkResponse } from "../models/dto/BulkResponse";

const SPILL_REPORT_ROUTE = "/api/v1/spillReport";
const SPILL_REPORT_DOWNLOAD_ROUTE = "api/v1/spillReport/public";
const STATE_REPORT_DOWNLOAD_ROUTE = "api/v1/spillReport/legal-letter";
const SPILL_REPORT_DOWNLOAD_ID = (reportId) =>
	`${SPILL_REPORT_DOWNLOAD_ROUTE}/${reportId}`;
const SPILL_REPORT_ID = (id) => `${SPILL_REPORT_ROUTE}/${id}`;
const STATE_REPORT_DOWNLOAD_BY_STATE = (reportId) =>
	`${STATE_REPORT_DOWNLOAD_ROUTE}/${reportId}`;
const SPILL_REPORT_BULK_DELETE = `${SPILL_REPORT_ROUTE}/bulk-delete`;
export const SPILL_REPORT_IMAGE_NEW = (reportId) =>
	`${SPILL_REPORT_ID(reportId)}/photos`;
const SPILL_REPORT_ALL = "/api/v1/spillReport/all/web";
const SPILL_REPORT_SEARCH = `${SPILL_REPORT_ROUTE}/search`;
const SPILL_REPORT_BULK_UPLOAD = `${SPILL_REPORT_ROUTE}/bulk-upload`;
const SPILL_REPORT_STATES = `${SPILL_REPORT_ROUTE}/states`;
const SPILL_REPORT_CSV = `${SPILL_REPORT_ROUTE}/csv`;

export const enum RELATIONS {
	REM_REPORT = "remediation_report",
}

@Injectable({ providedIn: "root" })
export class SpillReportService {
	constructor(private http: HttpClient) {}

	getByReportId(id: number): Observable<SpillReportModel> {
		return this.http
			.get<SpillReportModel>(SPILL_REPORT_ID(id))
			.pipe(map((report) => new SpillReportModel(report)));
	}

	getAll(filters?: Filter): Observable<ListDto<SpillReportModel>> {
		const options = filters ? { params: filters } : {};
		return this.http
			.get<ListDto<SpillReportModel>>(SPILL_REPORT_ALL, options)
			.pipe(
				map((res) => {
					return {
						data: res.data.map((v) => new SpillReportModel(v)),
						meta: res.meta,
					};
				})
			);
	}

	search(filters?: Filter): Observable<SpillReportModel[]> {
		const options = filters ? { params: filters } : {};
		return this.http.get<SpillReportModel[]>(SPILL_REPORT_SEARCH, options);
	}

	update(id: number, params: Partial<SpillReportModel>) {
		return this.http
			.put<SpillReportModel>(SPILL_REPORT_ID(id), params)
			.pipe(map((report) => new SpillReportModel(report)));
	}

	uploadImage(
		params: UploadFileRequestDto,
		endpoint: string
	): Observable<FileItem> {
		const formData = new FormData();
		formData.append("file", params.file);
		const headers = new HttpHeaders({ "x-photo-tag": params.fileTag });
		return this.http.post<FileItem>(endpoint, formData, {
			reportProgress: true,
			headers,
		});
	}

	deleteImage(image: FileItem, endpoint: string): Observable<void> {
		return this.http.delete<void>(endpoint, {
			body: image,
		});
	}

	bulkUpload(csv: File): Observable<BulkResponse> {
		const formData = new FormData();
		formData.append("file", csv);
		return this.http.post<BulkResponse>(SPILL_REPORT_BULK_UPLOAD, formData);
	}

	delete(spillIds: number[]): Observable<BulkResponse> {
		return this.http.delete<BulkResponse>(SPILL_REPORT_BULK_DELETE, {
			body: spillIds,
		});
	}

	generateCsv(filters?: Filter): Observable<Blob> {
		return this.http.get(SPILL_REPORT_CSV, {
			responseType: "blob",
			params: filters,
		});
	}

	spillReportDownload(reportId: number): Observable<any> {
		return this.http.get(SPILL_REPORT_DOWNLOAD_ID(reportId));
	}

	stateReportDownload(reportId: number): Observable<any> {
		return this.http.get(STATE_REPORT_DOWNLOAD_BY_STATE(reportId));
	}
}
