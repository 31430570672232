import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import {
	InvoiceBulkUpdateDto,
	InvoiceModel,
	InvoiceType,
} from "../models/invoice";
import { map } from "rxjs/operators";
import { Filter } from "../models/filters";
import { ListDto } from "../models/list-dto";
import { RemediationReportModel } from "../models/remediation-report";
import { FileModel, UploadFileRequestDto } from "../models/file";
import { CollectionBulkUpdateDto } from "../models/collection";

const INVOICE_ROUTE = "/api/v1/invoices";
const INVOICE_BULK_ROUTE = `${INVOICE_ROUTE}/bulk`;
const INVOICE_ALL_MONDAY = `${INVOICE_ROUTE}/monday`;
const INVOICE_BULK_DOWNLOAD = `${INVOICE_BULK_ROUTE}/download`;
const INVOICE_BULK_REGENERATE = `${INVOICE_BULK_ROUTE}/regenerate`;
const INVOICE_ITEMIZED_BILL_REPORT_DOWNLOAD = (invoiceId) => `${INVOICE_ROUTE}/public/itemized-bill/${invoiceId}`
const INVOICE_ITEMIZED_BILL = (id) => `${INVOICE_ROUTE}/itemized-bill/${id}`;
const INVOICE_ID = (id) => `${INVOICE_ROUTE}/${id}`;
const INVOICE_FILES = (id) => `${INVOICE_ROUTE}/files/${id}`;
const INVOICE_BULK_UPDATE = `${INVOICE_ROUTE}/bulk`;
const REPORTING_ROUTE = "/api/v1/reports";
const STATES_ALL = `${REPORTING_ROUTE}/states`;
const INVOICES_ALL = `${REPORTING_ROUTE}/invoices`;
const INVOICE_REPORT_CSV = `${INVOICE_ROUTE}/csv`;

const INVOICE_COLLECTION_ROUTE = "/api/v1/collections";
const COLLECTION_INVOICE_ID = (id) => `${INVOICE_COLLECTION_ROUTE}/${id}`;
const COLLECTION_STAFF = (id) => `${INVOICE_COLLECTION_ROUTE}/admin-staff/${id}`;
const COLLECTION_BULK_UPDATE = `${INVOICE_COLLECTION_ROUTE}/transfer-collections`;


@Injectable({ providedIn: "root" })
export class InvoiceService {
	constructor(private http: HttpClient) {}

	getAll(filters?: Filter): Observable<ListDto<RemediationReportModel>> {
		const options = filters ? { params: filters } : {};
		return this.http
			.get<ListDto<RemediationReportModel>>(INVOICE_ROUTE, options)
			.pipe(
				map((res) => {
					return {
						data: res.data.map((v) => new RemediationReportModel(v)),
						meta: res.meta,
					};
				})
			);
	}

	getByInvoiceId(id: number): Observable<RemediationReportModel> {
		return this.http
			.get<RemediationReportModel>(INVOICE_ID(id))
			.pipe(map((report) => new RemediationReportModel(report)));
	}

	getAllMonday(filters?: Filter): Observable<ListDto<RemediationReportModel>> {
		const options = filters ? { params: filters } : {};
		return this.http
			.get<ListDto<RemediationReportModel>>(INVOICE_ALL_MONDAY, options)
			.pipe(
				map((res) => {
					return {
						data: res.data.map((v) => new RemediationReportModel(v)),
						meta: res.meta,
					};
				})
			);
	}

	generateItemizedBill(invoiceId: number): Observable<Blob> {
		return this.http.post(
			INVOICE_ITEMIZED_BILL(invoiceId),
			{},
			{
				responseType: "blob",
			}
		);
	}

	update(id: number, params: Partial<InvoiceModel>) {
		return this.http
			.put<InvoiceModel>(INVOICE_ID(id), params)
			.pipe(map((report) => new InvoiceModel(report)));
	}

	collectionUpdate(id: number, params: Partial<InvoiceModel>) {
		return this.http
			.put<InvoiceModel>(COLLECTION_INVOICE_ID(id), params)
			.pipe(map((report) => new InvoiceModel(report)));
	}

	collectionStaffUpdate(invoiceId:number, userDetails:any){
		return this.http
		.put<InvoiceModel>(COLLECTION_STAFF(invoiceId), userDetails)
		.pipe(map((report) => new InvoiceModel(report)));
	}

	collectionBulkUpdate(bulkUpdates: CollectionBulkUpdateDto): Observable<void> {
		return this.http.post<void>(COLLECTION_BULK_UPDATE, bulkUpdates.invoiceIds);
	}

	bulkUpdate(bulkUpdates: InvoiceBulkUpdateDto): Observable<void> {
		return this.http.put<void>(INVOICE_BULK_UPDATE, bulkUpdates);
	}

	uploadInvoiceFile(params: UploadFileRequestDto): Observable<FileModel> {
		const formData = new FormData();
		formData.append("file", params.file);
		const headers = new HttpHeaders({ "x-file-tag": params.fileTag });
		return this.http.post<FileModel>(INVOICE_FILES(params.reportId), formData, {
			reportProgress: true,
			headers,
		});
	}

	deleteInvoiceFile(depositId: number, file: FileModel): Observable<void> {
		return this.http.delete<void>(INVOICE_FILES(depositId), {
			body: file,
		});
	}

	invoiceBulkDownload(invoiceIds: number[]): Observable<FileModel> {
		return this.http.post<FileModel>(INVOICE_BULK_DOWNLOAD, invoiceIds);
	}

	invoiceBulkRegenerate(invoiceIds: number[]): Observable<void> {
		return this.http.post<void>(INVOICE_BULK_REGENERATE, invoiceIds);
	}

	//TODO: Put listing endpoints in a separate reporting/typeahead service
	getIncidentStates() {
		return this.http.get<string[]>(STATES_ALL);
	}

	getUnusedIncidentIds(): Observable<string[]> {
		return this.http.get<string[]>(INVOICES_ALL);
	}

	generateCsv(filters?: Filter): Observable<Blob> {
		return this.http.get(INVOICE_REPORT_CSV, {
			responseType: "blob",
			params: filters,
		});
	}
	
	itemizedBillReportDownload(invoiceId: number): Observable<Blob> {
		return this.http.get(INVOICE_ITEMIZED_BILL_REPORT_DOWNLOAD(invoiceId), {
			responseType: 'blob'			
		});
	}

	getCollectionAll(filters?: Filter): Observable<ListDto<RemediationReportModel>> {
		const options = filters ? { params: filters } : {};
		return this.http
			.get<ListDto<RemediationReportModel>>(INVOICE_COLLECTION_ROUTE, options)
			.pipe(
				map((res) => {
					return {
						data: res.data.map((v) => new RemediationReportModel(v)),
						meta: res.meta,
					};
				})
			);
	}
}
