import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {Roles} from "../../../helpers/permissions";

@Directive({
  selector: '[requiresRole]'
})
export class RequiresRoleDirective {

	@Input() requiresRole: Roles[];
	private viewCreated: boolean;

	constructor(private userService: UserService,
				private templateRef: TemplateRef<any>,
				private viewContainer: ViewContainerRef
	) {
	}

	ngOnInit(): void {
		const user = this.userService.getCurrentUser()
		if (this.requiresRole.includes(user.role.role as Roles) && !this.viewCreated) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.viewCreated = true;
		} else {
			this.viewContainer.clear();
			this.viewCreated = false;
		}
	}

}
