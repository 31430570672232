import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {

	static date(control: FormControl): { [key: string]: any } {
		const date = moment(control.value);
		if(!date.isValid()){
			return {"date": true};
		}
		return null;
	}

	static time(control: FormControl): { [key: string]: any } {
		const timePattern = /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/;
		if(!control.value.match(timePattern)){
			return {"time": true}
		}
		return null;
	}
}
