import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {tap} from "rxjs/operators";

export interface PaginationParams {
	currentPage: number;
	total: number;
	pageSize: number;
}

export const DEFAULT_PAGE_SIZE = 25;

@Component({
	selector: 'app-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, PaginationParams {

	@Input() currentPage: number;
	@Input() total: number;
	@Input() pageSize: number; // limit
	@Input() editPageLimit = true;
	@Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
	
	pageSizes: number[] = [DEFAULT_PAGE_SIZE, 50, 100, 200, 500, 1000, 1500, 2000];
	form: FormGroup;
	paginationNum: number;

	readonly maxPagesShown: number = 7; // this sets the max number of page numbers displayed in the paginator

	constructor(private formBuilder: FormBuilder,
				private router: Router,
				private route: ActivatedRoute) {
	}

	ngOnChanges() {
		this.paginationNum = this.currentPage + 1;
	}

	ngOnInit(): void {
		this.route.queryParams.pipe(
			tap(params => {
				this.pageSize = params['limit'] || DEFAULT_PAGE_SIZE;
			})
		).subscribe()
		this.form = this.formBuilder.group({
			pageSize: this.formBuilder.control(DEFAULT_PAGE_SIZE, []),
			pageNum: this.formBuilder.control('')
		});
		this.form.patchValue({
			pageSize: this.pageSize
		});
		this.paginationNum = this.currentPage + 1;
	}

	get pages(): number[] {
		return Array(this.totalPages).fill(0).map((_, index) => index);
	}

	get totalPages(): number {
		return Math.ceil(this.total / this.pageSize)
	}

	get isFirstPage(): boolean {
		return this.currentPage === 0;
	}

	get isLastPage(): boolean {
		return this.currentPage === (this.totalPages - 1);
	}

	onPageSizeChange($event) {
		this.pageSizeChange.emit($event.target.value);
	}

	fitsAroundCurrPage(page: number): boolean {
		const nSidePages = Math.floor(this.maxPagesShown / 2); // max num of pages on the left or right
		let [lBound, rBound] = [this.currentPage - nSidePages, this.currentPage + nSidePages];
		const lDiff = lBound < 0 ? -lBound : 0;
		const rDiff = rBound > this.totalPages - 1 ? rBound - (this.totalPages - 1) : 0;
		[lBound, rBound] = [Math.max(0, lBound - rDiff), Math.min(this.totalPages - 1, rBound + lDiff)];
		return page >= lBound && page <= rBound;
	}

	goToPage(queryParams: object){
		this.router.navigate([], {relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge',});
	}

}
