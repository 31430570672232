export namespace AppRoutes {

	export enum User {
		LIST = 'users',
		EDIT = 'users/:userId',
		ADD = 'users/new'
	}

	export enum Drivers {
		LIST = 'drivers',
		VIEW = 'drivers/:userId/summary',
		EDIT = 'drivers/:userId',
		ADD = 'drivers/new'
	}

	export enum Cleaners {
		LIST = 'cleaners',
		VIEW = 'cleaners/:userId/summary',
		EDIT = 'cleaners/:userId',
		ADD = 'cleaners/new'
	}

	export enum Admins {
		LIST = 'admins',
		VIEW = 'admins/:userId/summary',
		EDIT = 'admins/:userId',
		ADD = 'admins/new'
	}

	export enum BillingAdmins {
		LIST = 'billingAdmins',
		VIEW = 'billingAdmins/:userId/summary',
		EDIT = 'billingAdmins/:userId',
		ADD = 'billingAdmins/new'
	}

	export enum CollectionAdmins {
		LIST = 'collectionAdmins',
		VIEW = 'collectionAdmins/:userId/summary',
		EDIT = 'collectionAdmins/:userId',
		ADD = 'collectionAdmins/new'
	}

	export enum SuperAdmins {
		LIST = 'superAdmins',
		VIEW = 'superAdmins/:userId/summary',
		EDIT = 'superAdmins/:userId',
		ADD = 'superAdmins/new'
	}

	export enum CompanyAdmins {
		LIST = 'companyAdmins',
		VIEW = 'companyAdmins/:userId/summary',
		EDIT = 'companyAdmins/:userId',
		ADD = 'companyAdmins/new'
	}

	export enum SpillReport {
		LIST = 'reports',
		VIEW = 'reports/:reportId/summary',
		EDIT = 'reports/:reportId',
	}

	export enum RemediationReport {
		LIST = 'remediation-reports',
		// VIEW = 'reports/:reportId/summary',
		EDIT = 'remediation-reports/:reportId',
	}

	export enum Companies {
		LIST = 'companies',
		VIEW = 'companies/:companyId/summary',
		EDIT = 'companies/:companyId',
		ADD = 'companies/new'
	}

	export enum QualityAssurance {
		LIST = 'quality-assurance',
		VIEW = 'quality-assurance/:reportId',
	}

	export enum Invoices {
		LIST = 'invoices',
		VIEW = 'invoices/:reportId',
	}

	export enum Collections {
		LIST = 'collections',
		VIEW = 'collections/:reportId',
	}

	export enum Deposits {
		LIST = 'deposits',
		VIEW = 'deposits/:depositId',
	}

	export enum Settings {
		ADD = 'settings',
		EDIT = 'settings/:id',
	}

	export enum InsuranceCompanies {
		LIST = 'insurance',
		EDIT = 'insurance/:insuranceId',
		ADD = 'insurance/new',
	}

	export enum Statistics {
		LIST = 'statistics',
	}

	export enum Account {
		EDIT = 'account',
	}

	export enum Home {
		LIST = '',
	}
}
