<div class="dashboard-shell" id="dashboard-shell" [ngClass]="navService.isCollapsed ? 'overflow-x-hidden' : ''">

    <div class="nav-overlay d-lg-none" *ngIf="navService.isCollapsed" [@menuOverlay]></div>
    <div class="sidebar mobile d-lg-none" *ngIf="navService.isCollapsed" [@menuPopout]>
        <navigation (close)="navService.isCollapsed = !navService.isCollapsed"></navigation>
    </div>

    <div class="sidebar d-block z-20" [ngClass]="navService.isCollapsed ? 'collapsed' : ''">
        <navigation></navigation>
    </div>

    <div class="content-area">
		<nav class="navbar navbar-expand navbar-light navbar-bg z-20 position-lg-fixed">
			<a class="sidebar-toggle me-n3" (click)="mobileNavToggle()">
				<i class="hamburger align-self-center"></i>
			</a>
			<a class="mx-auto d-block d-lg-none" [routerLink]="'/reports'">
				<img src="assets/images/cts_blue.png" height="15px">
			</a>
		</nav>
        <div class="container-fluid py-4 p-md-5 main-container mt-5 mt-lg-0">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>
