export enum FIELD_TYPES {
	NUMBER = "number",
	TEXT = "text",
	BOOLEAN = "boolean",
	DROPDOWNTEXT = "dropdown"
}

export interface OptionValue {
	label: string;
	value: any;
}

export interface DateOptionValue {
	label: string;
	value: any;
	nullCheck?: boolean;
	isPublic?: boolean;
}

export interface Filter {
	[key: string]: any;
}

export interface Relation {
	[key: string]: any;
}

export class FilterField {
	label: string;
	property: string;
	type: string;
	options?: OptionValue[];
	selectedOption?: OptionValue;
	isPublic?: boolean = false;
}

export function dataFilter(field: string, fieldVal: string): object {
	return fieldVal ? {[`filters[${field}]`]: fieldVal.trim()} : {};
}

export const defaultBoolOptions: OptionValue[] = [
	{label: 'Yes', value: true},
	{label: 'No', value: false},
];
