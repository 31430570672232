import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {hasPermission} from "../../../helpers/permission-check";

@Directive({
    selector: '[requiresPermission]'
})
export class RequiresPermissionDirective implements OnInit {

    @Input() requiresPermission: string;
    private viewCreated: boolean;

    @Input() requiresPermissionElse: TemplateRef<any>;


    constructor(private userService: UserService,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef
    ) {
    }

    ngOnInit(): void {
        const user = this.userService.getCurrentUser()
        if (hasPermission(user.role, this.requiresPermission) && !this.viewCreated) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.viewCreated = true;
        } else if (this.requiresPermissionElse && !this.viewCreated) {
            this.viewContainer.createEmbeddedView(this.requiresPermissionElse);
            this.viewCreated = true;
        } else {
            this.viewContainer.clear();
            this.viewCreated = false;
        }
    }

}
