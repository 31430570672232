import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
	@Input() alertToggle = true;
	@Input() alertType: string;
	@Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

	hideAlert() {
		this.alertToggle = false;
	}

}
