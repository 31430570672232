import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PasswordFormComponent } from "./password-form/password-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { UserFormComponent } from "./user-form/user-form.component";
import { EmptyStateComponent } from "./empty-state/empty-state.component";
import { WarningModalComponent } from "./warning-modal/warning-modal.component";
import {
	NgbCollapseModule,
	NgbDatepickerModule,
	NgbProgressbarModule,
	NgbTypeaheadModule,
	NgbDropdownModule,
} from "@ng-bootstrap/ng-bootstrap";
import { RequiresPermissionDirective } from "./requires-permission-directive/requires-permission.directive";
import { AlertComponent } from "./alert/alert.component";
import { FilterBarComponent } from "./filter-bar/filter-bar.component";
import { PaginatorComponent } from "./paginator/paginator.component";
import { RouterModule } from "@angular/router";
import { LocalizedDatePipe } from "./localized-date.pipe";
import { SortableHeaderDirective } from "./sortable-header-directive/sortable-header.directive";
import { RequiresRoleDirective } from "./requires-role-directive/requires-role.directive";
import { DateTimezonePipe } from "./DatePipe";

@NgModule({
	declarations: [
		PasswordFormComponent,
		PageHeaderComponent,
		UserFormComponent,
		EmptyStateComponent,
		WarningModalComponent,
		RequiresPermissionDirective,
		AlertComponent,
		FilterBarComponent,
		PaginatorComponent,
		LocalizedDatePipe,
		SortableHeaderDirective,
		RequiresRoleDirective,
		DateTimezonePipe,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		PasswordStrengthMeterModule,
		NgbProgressbarModule,
		NgbDatepickerModule,
		NgbCollapseModule,
		NgbTypeaheadModule,
		NgbDropdownModule,
		RouterModule,
	],
	exports: [
		PasswordFormComponent,
		PageHeaderComponent,
		UserFormComponent,
		EmptyStateComponent,
		RequiresPermissionDirective,
		AlertComponent,
		FilterBarComponent,
		PaginatorComponent,
		LocalizedDatePipe,
		SortableHeaderDirective,
		RequiresRoleDirective,
		DateTimezonePipe,
	],
})
export class SharedModule {}
