import { Component, Inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
	dataFilter,
	DateOptionValue,
	FIELD_TYPES,
	FilterField,
	OptionValue,
} from "../../../models/filters";
import * as _ from "lodash";
import { filterMenuAnimations } from "../../../helpers/animation";
import { PROPERTY_SEARCH } from "../../../services/search/property-search";
import { NgbDate, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";

@Component({
	selector: "app-filter-bar",
	templateUrl: "./filter-bar.component.html",
	styleUrls: ["./filter-bar.component.scss"],
	animations: filterMenuAnimations,
})
export class FilterBarComponent implements OnInit {
	@Input() filterFields: FilterField[];
	@Input() searchFields: FilterField[];
	@Input() dateOptions: DateOptionValue[];
	@Input() baseParams: string[];
	@Input() isMasterSearch: boolean = false;
	@Input() isSpillReport: boolean = false;

	form: FormGroup;
	searchForm: FormGroup;
	isCollapsed = true;
	appliedFilters: FilterField[] = [];
	selectedDateField: DateOptionValue;
	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	isDateTouched = false;
	currentSearch: FilterField;
	masterSearchForm: FormGroup;
	searchPlaceholder: string = "Master Search";

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		public formatter: NgbDateParserFormatter,
		@Inject(PROPERTY_SEARCH) public propertyFilter: any
	) {}

	ngOnInit(): void {
		if (this.isSpillReport) {
			this.searchPlaceholder = "Insured Name";
		}
		
		this.sortFilters();
		this.form = this.fb.group({
			propertyLookup: this.fb.control(null, []),
			masterPropertyLookup: this.fb.control(null, []),
		});
		this.searchForm = this.fb.group({
			propertyLookup: this.fb.control(null, [])
		});

		this.masterSearchForm = this.fb.group({
			masterPropertyLookup: this.fb.control(null, [])
		});

		this.masterSearchForm = this.fb.group({
			masterPropertyLookup: this.fb.control(null, []),
		});

		this.route.queryParams.subscribe((params) => {
			do {
				this.removeFilter(this.appliedFilters[0]);
			} while (this.appliedFilters.length > 0);
			this.parseFilters(params);
		});
		this.sortFilters();
		if (this.searchFields?.length > 0) {
			this.currentSearch = this.searchFields[0];
		}
		if (this.dateOptions?.length === 1) {
			this.selectedDateField = this.dateOptions[0];
		}
	}

	selectTypeaheadItem(input: FilterField, search = false): void {
		if (search) {
			this.currentSearch = input;
		}
		this.propertyFilter.currentFilter = input;
		this.propertyFilter.filterFields = this.filterFields;
	}

	selectFilterValue($event: any): void {
		this.propertyFilter.currentFilter.selectedOption = $event.item;
		this.appliedFilters.push(this.propertyFilter.currentFilter);
		_.pull(this.filterFields, this.propertyFilter.currentFilter);
		this.propertyFilter.currentFilter = null;
		setTimeout(() => {
			this.f.propertyLookup.patchValue("");
		}, 200);
	}

	applyAll(): void {
		const newParams = {};
		this.appliedFilters.forEach((f) => {
			newParams[`filters[${f.property}]`] = f.selectedOption.value;
		});
		if (this.isDateTouched && this.fromDate && this.toDate) {
			const fromDate = DateTime.local(
				this.fromDate.year,
				this.fromDate.month,
				this.fromDate.day
			)
				.startOf("day")
				.toUTC()
				.toString();
			const toDate = DateTime.local(
				this.toDate.year,
				this.toDate.month,
				this.toDate.day
			)
				.endOf("day")
				.toUTC()
				.toString();
			newParams[`filters[${this.selectedDateField.value}][from]`] = fromDate;
			newParams[`filters[${this.selectedDateField.value}][to]`] = toDate;
		}
		this.router.navigate([], {
			queryParams: { ...newParams, ...this.getBaseParams() },
		});
	}

	clearAll(): void {
		this.appliedFilters.forEach((f) => this.removeFilter(f));
		this.isDateTouched = false;
		[this.fromDate, this.toDate] = [null, null];
		this.selectedDateField = this.dateOptions[0];
		this.router.navigate([], {
			queryParams: this.getBaseParams(),
		});
	}

	parseFilters(params: any): void {
		Object.entries(params).forEach(([k, v]) => {
			const matched = k.match(/\[(.*?)\]/);
			if (!matched) return;

			const filter = this.filterFields.find((f) => f.property === matched[1]);
			if (!filter) return;

			filter.selectedOption = this.getTypedOp(filter, v);
			this.appliedFilters.push(filter);
			_.pull(this.filterFields, filter);
		});
	}

	removeFilter(f: FilterField): void {
		if (!f) return;
		_.pull(this.appliedFilters, f);
		this.filterFields.push(f);
		this.sortFilters();
	}

	sortFilters(): void {
		this.filterFields = _.sortBy(this.filterFields, ["label"]);
	}

	onFocus($event): void {
		this.propertyFilter.focus$.next($event);
	}

	get f() {
		return this.form.controls;
	}

	private getTypedOp(filter: FilterField, val: any): OptionValue {
		let typedVal = val;
		if (filter.type === FIELD_TYPES.BOOLEAN) {
			typedVal = val === "true";
		} else if (filter.type === FIELD_TYPES.NUMBER) {
			typedVal = parseInt(val);
		}

		if (filter.options) {
			return filter.options?.find(
				(o) => o.value === typedVal || o.value === Number(typedVal)
			);
		}
		return { label: val as string, value: val as string };
	}

	search(): void {
		const formVal = { ...this.searchForm.value };
		const searchFilter = dataFilter(
			this.currentSearch.property,
			formVal.propertyLookup
		);
		const merged = Object.assign(this.getBaseParams(), searchFilter);
		this.masterSearchForm.controls["masterPropertyLookup"].reset();
		this.router.navigate([], {
			queryParams: merged,
		});
	}

	clearSearch(): void {
		this.searchForm.reset();
		this.router.navigate([], {
			queryParams: this.getBaseParams(),
		});
	}

	get currentSearchValue() {
		return this.searchForm.get("propertyLookup").value;
	}

	selectDateField(input: DateOptionValue): void {
		this.selectedDateField = input;
		if (this.selectedDateField.nullCheck) {
			this.isDateTouched = true;
			this.fromDate = null;
			this.toDate = null;
		} else {
			this.isDateTouched = false;
		}
	}

	onDateSelection(date: NgbDate) {
		this.isDateTouched = true;
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (
			this.fromDate &&
			!this.toDate &&
			date &&
			date.after(this.fromDate)
		) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	formatDateRange() {
		const from = this.formatter.format(this.fromDate);
		const to = this.formatter.format(this.toDate);
		return `${from} - ${to}`;
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate &&
			!this.toDate &&
			this.hoveredDate &&
			date.after(this.fromDate) &&
			date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	getBaseParams() {
		const baseParams = {};
		this.route.queryParams.subscribe((params) => {
			baseParams["tab"] = params["tab"];
			this.baseParams?.forEach((p) => {
				baseParams[p] = params[p];
			});
			baseParams["limit"] = params["limit"];
		});
		return baseParams;
	}

	masterSearch(): void {
		setTimeout(() => {
			const formVal = { ...this.masterSearchForm.value };
			const searchFilter = dataFilter(
				"master_search",
				formVal.masterPropertyLookup
			);
			const merged = Object.assign(this.getBaseParams(), searchFilter);
			this.router.navigate([], {
				queryParams: merged,
			});
		}, 1500);
	}
}
