import {
	ImageTags,
	ImageTagsLabel,
	SpillReportModel,
	SpillReportStatusOptions,
	ValidationControls
} from './spill-report';
import * as _ from 'lodash';
import {User} from './user';
import {DateOptionValue, FIELD_TYPES, FilterField} from './filters';
import {HistoryModel, InvoiceModel} from './invoice';

export interface QAController extends ValidationControls{
	[key: string]: {
		show: boolean;
		index: number;
		statusField: string;
		feedbackField: string;
		successToggle: boolean;
		failFeedbackToggle: boolean;
	};
}

export enum RemediationStatus {
	NONE = 'none',
	SAVED = 'saved',
	FINISHED = 'finished',
	MOVED_TO_MONDAY = 'moved_to_monday',
	NSF_UNSAFE = 'nsf_unsafe'
}

export enum RemediationStatusLabel {
	NONE = 'Not Started',
	SAVED = 'Incomplete',
	FINISHED = 'Cleaned',
	MOVED_TO_MONDAY = 'Moved To Monday',
	NSF_UNSAFE = 'NSF/Unsafe'
}

export const remediationValueOptions = [
	{label: RemediationStatusLabel.NONE, value: RemediationStatus.NONE},
	{label: RemediationStatusLabel.SAVED, value: RemediationStatus.SAVED},
	{label: RemediationStatusLabel.FINISHED, value: RemediationStatus.FINISHED},
	{label: RemediationStatusLabel.MOVED_TO_MONDAY, value: RemediationStatus.MOVED_TO_MONDAY},
	{label: RemediationStatusLabel.NSF_UNSAFE, value: RemediationStatus.NSF_UNSAFE},
	{label: 'Confirm', value: null},
];


export const REMEDIATION_REPORT_VALIDATION_CONTROLS: ValidationControls = {
	generalInfo: {
		formControls: ['user', 'remediation_date_str', 'remediation_time_str', 'hazmat_level', 'additional_time_required'],
		show: true,
		index: 0
	},
	consumables: {
		formControls: ['stain_lifter_coats', 'remediator_coats', 'absorbent_pads', 'bags_of_debris'],
		show: false,
		index: 1
	},
	narratives: {
		formControls: ['scene_narrative', 'report_narrative', 'additional_notes'],
		show: false,
		index: 2
	},
	histories: {
		formControls: [],
		show: false,
		index: 3
	}
};

export const QUALITY_ASSURANCE_CONTROLLER: QAController = {
	locationInfo: {
		show: true,
		index: 0,
		statusField: 'location_status',
		feedbackField: 'location_feedback',
		successToggle: true,
		failFeedbackToggle: false
	},
	pictures: {
		show: false,
		index: 1,
		statusField: 'pictures_status',
		feedbackField: 'pictures_feedback',
		successToggle: true,
		failFeedbackToggle: false
	},
	narratives: {
		show: false,
		index: 2,
		statusField: 'narratives_status',
		feedbackField: 'narratives_feedback',
		successToggle: true,
		failFeedbackToggle: false
	}
};

export const RemediationReportStatusOptions = [
	{ label: RemediationStatusLabel.NONE, value: RemediationStatus.NONE},
	{ label: RemediationStatusLabel.SAVED, value: RemediationStatus.SAVED},
	{ label: RemediationStatusLabel.FINISHED, value: RemediationStatus.FINISHED},
	{ label: RemediationStatusLabel.MOVED_TO_MONDAY, value: RemediationStatus.MOVED_TO_MONDAY},
	{ label: RemediationStatusLabel.NSF_UNSAFE, value: RemediationStatus.NSF_UNSAFE}
];

export enum QualityAssuranceStatus {
	PENDING_INSPECTION= 'pending_inspection',
	FAILED = 'failed',
	PASSED = 'passed'
}

export enum QualityAssuranceStatusLabel {
	PENDING_INSPECTION = 'Pending Inspection',
	FAILED = 'Failed',
	PASSED = 'Passed'
}

const QA_STATUSES = [
	{ label: QualityAssuranceStatusLabel.PENDING_INSPECTION, value: QualityAssuranceStatus.PENDING_INSPECTION},
	{ label: QualityAssuranceStatusLabel.FAILED, value: QualityAssuranceStatus.FAILED},
	{ label: QualityAssuranceStatusLabel.PASSED, value: QualityAssuranceStatus.PASSED}
];

export const QAPictureOptions = [...QA_STATUSES];
export const QALocationOptions = [...QA_STATUSES];
export const QANarrativeOptions = [...QA_STATUSES];

export const Remediation = [
	{ label: RemediationStatusLabel.NONE, value: RemediationStatus.NONE},
	{ label: RemediationStatusLabel.SAVED, value: RemediationStatus.SAVED},
	{ label: RemediationStatusLabel.FINISHED, value: RemediationStatus.FINISHED},
	{ label: RemediationStatusLabel.MOVED_TO_MONDAY, value: RemediationStatus.MOVED_TO_MONDAY},
	{ label: RemediationStatusLabel.NSF_UNSAFE, value: RemediationStatus.NSF_UNSAFE}
];

export enum QAFilterFields {
	PICTURES_STATUS = 'pictures_status',
	LOCATION_STATUS = 'location_status',
	NARRATIVE_STATUS = 'narratives_status',
}

export const remediationHistoryLabels = [
	...RemediationReportStatusOptions,
	...QA_STATUSES,
	{ label: ImageTagsLabel.REMEDIATION_REPORT_LOCATION, value: ImageTags.REMEDIATION_REPORT_LOCATION},
	{ label: ImageTagsLabel.BEFORE_REMEDIATION, value: ImageTags.BEFORE_REMEDIATION},
	{ label: ImageTagsLabel.REMEDIATION_APPLICATION, value: ImageTags.REMEDIATION_APPLICATION},
	{ label: ImageTagsLabel.AFTER_REMEDIATION_FINAL, value: ImageTags.AFTER_REMEDIATION_FINAL},
]

export const REMEDIATION_FILTER_FIELDS: FilterField[] = [
	{label: 'Remediation Status', property: 'status', type: FIELD_TYPES.TEXT, isPublic: true, options: RemediationReportStatusOptions},
	{label: 'Spill Status', property: 'incident_status', type: FIELD_TYPES.TEXT, isPublic: true, options: SpillReportStatusOptions},
	{label: 'Cleaner', property: 'cleaner', type: FIELD_TYPES.TEXT, isPublic: true},
	{label: 'State', property: 'incident_state', type: FIELD_TYPES.TEXT, isPublic: true},
];

export const QUALITY_ASSURANCE_FILTER_FIELDS: FilterField[] = [
	{label: 'Quality Assurance Status', property: 'qaStatus', type: FIELD_TYPES.TEXT, isPublic: true, options: QAPictureOptions},
	{label: 'QA Pictures Status', property: QAFilterFields.PICTURES_STATUS, type: FIELD_TYPES.TEXT, isPublic: true, options: QAPictureOptions},
	{label: 'QA Location Status', property: QAFilterFields.LOCATION_STATUS, type: FIELD_TYPES.TEXT, isPublic: true, options: QALocationOptions},
	{label: 'QA Narrative Status', property: QAFilterFields.NARRATIVE_STATUS, type: FIELD_TYPES.TEXT, isPublic: true, options: QANarrativeOptions},
];

export const REMEDIATION_SEARCH_FIELDS: FilterField[] = [
	{label: 'Incident ID', property: 'incident_number', type: FIELD_TYPES.NUMBER, isPublic: true},
	{label: 'Spill ID', property: 'spillId', type: FIELD_TYPES.NUMBER, isPublic: true},
];

export const REMEDIATION_DATE_OPTIONS: DateOptionValue[] = [
	{
		label: 'Remediation Date',
		value: 'remediationDate',
		isPublic: true
	}
];

export const qualityAssuranceTabToBaseParams: Map<string, string[]> = new Map([
	['pendingQA', ['filters[status]', 'filters[qaStatus]', 'filters[incident_status]']],
	['failedQA', ['filters[status]', 'filters[qaStatus]', 'filters[incident_status]']],
	['allQA', ['filters[status]', 'filters[incident_status]']],
]);

export class RemediationReportModel {
	report_id: number;
	user: User;
	spill_report: SpillReportModel;
	remediation_date_str!: string;
	remediation_time_str!: string;
	remediation_date: string;
	hazmat_level: number;
	additional_time_required: number;
	stain_lifter_coats: number;
	remediator_coats: number;
	absorbent_pads: number;
	bags_of_debris: number;
	scene_narrative: string;
	incident_narrative: string;
	report_narrative: string;
	areas_inspected: string;
	additional_notes: string;
	invoice: InvoiceModel;
	status: RemediationStatus;
	is_confirmed: boolean;
	pictures_status: QualityAssuranceStatus;
	location_status: QualityAssuranceStatus;
	narratives_status: QualityAssuranceStatus;
	pictures_feedback: string;
	location_feedback: string;
	narratives_feedback: string;
	history: HistoryModel[];

	constructor(report: RemediationReportModel) {
		if(report?.invoice) {
			report.invoice = new InvoiceModel(report.invoice);
		}
		_.assign(this, report);
		this.setHistory();
	}

	private setHistory(): void {
		this.history = _.map(this.history, (h: HistoryModel) => {
			const logField = _.find(REMEDIATION_LOG_FIELDS, ['field', h.field]);
			h.prevValueLabel = remediationHistoryLabels.find(i => i.value === h.prevValue)?.label || h.prevValue;
			h.newValueLabel = remediationHistoryLabels.find(i => i.value === h.newValue)?.label || h.newValue;
			return _.assign(h, logField);
		});
		this.history.sort((a, b) => b.id - a.id);
	}
}

const REMEDIATION_LOG_FIELDS = [
	{field: 'status', label: 'Status Updated', ico: 'fa-leaf'},
	{field: 'scene_narrative', label: 'Scene Narrative Updated', ico: 'fa-typewriter'},
	{field: 'report_narrative', label: 'Report Narrative Updated', ico: 'fa-typewriter'},
	{field: 'additional_notes', label: 'Additional Notes Updated', ico: 'fa-typewriter'},
	{field: 'pictures_status', label: 'QA Pictures Status Updated', ico: 'fa-clipboard-list-check'},
	{field: 'location_status', label: 'QA Location Status Updated', ico: 'fa-clipboard-list-check'},
	{field: 'narratives_status', label: 'QA Narratives Status Updated', ico: 'fa-clipboard-list-check'},
	{field: 'photo_upload', label: 'Photo Upload', ico: 'fa-file-arrow-up'},
	{field: 'photo_deletion', label: 'Photo Deletion', ico: 'fa-trash-can'},
];

export class RemediationListItem {
	remediationReport: RemediationReportModel;
}

export function getRemediationReportStatus(status: RemediationStatus, style: boolean = false): string{
	if (status === RemediationStatus.NONE) {
		return style ? 'text-muted' : RemediationStatusLabel.NONE;
	}
	if (status === RemediationStatus.SAVED){
		return style ? 'text-danger' : RemediationStatusLabel.SAVED;
	}
	if (status === RemediationStatus.FINISHED){
		return style ? 'text-success' : RemediationStatusLabel.FINISHED;
	}
	if (status === RemediationStatus.MOVED_TO_MONDAY){
		return style ? 'text-info' : RemediationStatusLabel.MOVED_TO_MONDAY;
	}
	if (status === RemediationStatus.NSF_UNSAFE){
		return style ? 'text-warning' : RemediationStatusLabel.NSF_UNSAFE;
	}
	return '';
}

export function getDistanceInstant(lat1: number, lon1:number, lat2:number, lon2:number) {
	if(!lat1 || !lon1){
		return 0;
	}
	const earthRadiusKm = 6371;
  
	const dLat = degreesToRadians(lat2 - lat1);
	const dLon = degreesToRadians(lon2 - lon1);
  
	const a =
	  Math.sin(dLat / 2) * Math.sin(dLat / 2) +
	  Math.cos(degreesToRadians(lat1)) *
		Math.cos(degreesToRadians(lat2)) *
		Math.sin(dLon / 2) *
		Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
	return ((earthRadiusKm * c)*0.621371).toFixed(2);
}

function degreesToRadians(degrees: number) {
	return degrees * (Math.PI / 180);
}
