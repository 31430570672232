import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from './user.service';
import * as _ from 'lodash';
import {CompanyModel, CompanyPayload} from "../models/company";

const LOGIN_ROUTE = '/api/v1/login';
const SIGNUP_ROUTE = '/api/v1/signup';
const COMPANY_SIGNUP_ROUTE = '/api/v1/signup/company';
const RESET_PWD_ROUTE = '/api/v1/reset-password';
const UPDATE_PWD_ROUTE = '/api/v1/update-password';
const ACCEPT_INVITE_ROUTE = '/api/users/invitation';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	private logoutEvents$ = new EventEmitter<void>();

	constructor(
		private http: HttpClient,
		private router: Router,
		private userService: UserService
	) {

	}

	login(creds: any) {
		return this.http
			.post<any>(LOGIN_ROUTE, creds)
			.pipe(map(res => this.processUser(res)));
	}

	signUp(creds: any, invitationToken: string = null) {
		if (invitationToken) {
			_.assign(creds, {invitation_token: invitationToken});
			return this.http
				.put<any>(ACCEPT_INVITE_ROUTE, creds)
				.pipe(map(res => this.processUser(res)));
		}
		return this.http
			.post<any>(SIGNUP_ROUTE, creds)
			.pipe(map(res => this.processUser(res)));
	}

	signUpCompanyAdmin(creds: CompanyPayload): Observable<CompanyModel> {
		return this.http.post<CompanyModel>(COMPANY_SIGNUP_ROUTE, creds);
	}

	resetPwd(email: string): Observable<boolean> {
		return this.http.post<any>(RESET_PWD_ROUTE, {email});
	}

	updatePwd(pwdRecovery: any): Observable<any> {
		return this.http
			.put<any>(UPDATE_PWD_ROUTE, pwdRecovery)
			.pipe(map(res => this.processUser(res)));
	}

	logout() {
		localStorage.removeItem('currentUser');
		this.logoutEvents$.emit();
	}

	public onLogout(): Observable<void> {
		return this.logoutEvents$;
	}

	authCheck(state: RouterStateSnapshot = null) {
		const currentUser = this.userService.getCurrentUser();
		if (currentUser && currentUser.token) {
			return true;
		}

		if (state) {
			this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
			return false;
		}
	}

	private processUser(res: any): any {
		const user = res.user || res;
		localStorage.setItem(
			'currentUser',
			JSON.stringify(user)
		);
		return res;
	}
}
