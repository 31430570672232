import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import _ from 'lodash';

@Component({
    selector: 'password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent implements OnInit {
    @Input()
    requireCurrent: false;

    @Input()
    action: () => void;

    @Input()
    actionName = 'Submit';

    form: FormGroup;
    // tslint:disable-next-line:variable-name
    error = null;

    get controls() {
        return this.form.controls;
    }

    constructor(private fb: FormBuilder) {
        const group = {
            passwordNew: ['', Validators.required],
            passwordVerify: ['', Validators.required],
            passwordCurrent: undefined
        };

        if (this.requireCurrent) {
            group.passwordCurrent = ['', Validators.required]
        }

        this.form = this.fb.group(group);
    }

    ngOnInit(): void {
    }

    isInvalid(field: string, verify: boolean = false): boolean {
        const invalid = this.form.get(field).invalid;
        const touched = this.form.get(field).touched;
        if (verify) {
            return touched && this.controls.passwordNew.value !== this.controls.passwordVerify.value;
        }
        return invalid && (this.form.get(field).dirty || touched);
    }

    getPayload(): any {
        const payload = {
            new: this.controls.passwordNew.value,
            current: undefined
        };

        if (this.requireCurrent) {
            payload.current = this.controls.passwordCurrent.value;
        }

        return payload;
    }

    handleSubmit() {
        if (_.size(this.controls.passwordNew.value) === 0 || this.isInvalid('passwordVerify', true)) {
            this.error = {message: 'Password verification does not match!'};
            setTimeout(() => {
                this.error = null;
            }, 4000);
            return;
        }
        this.action();
    }

}
