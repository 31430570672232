<div data-theme="default" data-layout="fluid" data-sidebar-position="left">
	<nav id="sidebar" class="h-100 position-fixed overflow-auto">
		<div class="sidebar-content js-simplebar">
			<a class="sidebar-brand my-4" [routerLink]="['/']">
				<img class="w-75" src="./assets/images/ctslanding.png" alt="">
			</a>
			<ul class="sidebar-nav mt-5 mt-lg-0">
				<li class="sidebar-item" [routerLinkActive]="['active']">
					<a class="sidebar-link" [routerLink]="[appRoutes.Account.EDIT]">
						<i class="far fa-user me-2"></i>
						Account
					</a>
				</li>
				<li class="sidebar-item" *requiresPermission="permissions.Company.LIST" [routerLinkActive]="['active']">
					<a class="sidebar-link" [routerLink]="[appRoutes.Companies.LIST]">
						<i class="far fa-building me-2"></i>
						Tow Companies
					</a>
				</li>
				<li class="sidebar-item" *requiresPermission="permissions.InsuranceCompany.LIST" [routerLinkActive]="['active']">
					<a class="sidebar-link" [routerLink]="[appRoutes.InsuranceCompanies.LIST]">
						<i class="fa-regular fa-building-shield me-2"></i>
						Insurance Companies
					</a>
				</li>
				<li class="sidebar-item" *requiresPermission="permissions.StatisticalReport.LIST" [class.active]="isActive(appRoutes.Statistics.LIST)">
					<a class="sidebar-link" [routerLink]="[appRoutes.Statistics.LIST]" [queryParams]="{'reportType': statReportType.CLEANER}">
						<i class="fa-regular fa-chart-simple me-2"></i>
						Statistical Reports
					</a>
				</li>
				<li class="sidebar-item" *requiresPermission="permissions.SpillReport.LIST" [class.active]="isActive(appRoutes.SpillReport.LIST)">
					<a class="sidebar-link" [routerLink]="[appRoutes.SpillReport.LIST]" [queryParams]="{'filters[report_type]': 'police_report'}">
						<i class="fa-regular fa-tint me-2"></i>
						Spill Reports
					</a>
				<li class="sidebar-item" *requiresPermission="permissions.RemediationReport.LIST" [class.active]="isActive(appRoutes.RemediationReport.LIST)">
					<a class="sidebar-link" [routerLink]="[appRoutes.RemediationReport.LIST]" [queryParams]="{'filters[pendingClean]': '1'}">
						<i class="far fa-leaf me-2"></i>
						Remediation Reports
					</a>
				</li>
				<li class="sidebar-item" *requiresRole="[roles.ADMIN, roles.SUPER_ADMIN]" [class.active]="isActive(appRoutes.QualityAssurance.LIST)">
					<a class="sidebar-link" [routerLink]="[appRoutes.QualityAssurance.LIST]"
					 [queryParams]="{'tab': 'pendingQA', 'filters[status]': remediationStatus.FINISHED, 'filters[qaStatus]': qualityAssuranceStatus.PENDING_INSPECTION,
					 'filters[incident_status]': spillStatus.VERIFIED}">
						<i class="far fa-clipboard-list-check me-2"></i>
						Quality Assurance
					</a>
				</li>
				<li class="sidebar-item" *requiresPermission="permissions.Invoices.LIST" [class.active]="isActive(appRoutes.Invoices.LIST)">
					<a class="sidebar-link" [routerLink]="[appRoutes.Invoices.LIST]" [queryParams]="{'tab': 'roadsideInvoices','filters[exclude_incident_state]': 'TX', 'limit': 25}" >
						<i class="far fa-file-invoice-dollar me-2"></i>
						Cost Recovery
					</a>
				</li>
				<li class="sidebar-item"  [class.active]="isActive(appRoutes.Collections.LIST)">
					<a class="sidebar-link" [routerLink]="[appRoutes.Collections.LIST]" [queryParams]="{'tab': 'roadsideInvoices','filters[exclude_incident_state]': 'TX', 'limit': 25}" >
						<i class="far fa-file-invoice-dollar me-2"></i>
						Collections
					</a>
				</li>
				<li class="sidebar-item" *requiresPermission="permissions.Deposits.LIST" [class.active]="isActive(appRoutes.Deposits.LIST)">
					<a class="sidebar-link" [routerLink]="[appRoutes.Deposits.LIST]" [queryParams]="{'tab': 'rrDeposits', 'filters[mondayImport]': '0', 'filters[isTexasDeposit]': '0', 'limit': 25}">
						<i class="fa-light fa-money-check-dollar-pen me-2"></i>
						Deposits
					</a>
				</li>
				<li class="sidebar-item" *requiresPermission="permissions.User.LIST">
					<a (click)="navToggle()"  data-bs-target="#dashboards" data-bs-toggle="collapse" class="sidebar-link">
						<span class="align-middle">
							<i class="fa-regular fa-users me-2"></i>
							Users
						</span>
						<i [ngClass]="dashboardToggle ? 'rotate' : ''" class="fas fa-chevron-down fa-xs chevron"></i>
					</a>
					<ul id="dashboards" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar"
						[ngClass]="dashboardToggle ? 'show' : ''">
						<li *requiresRole="[roles.SUPER_ADMIN]"
							class="sidebar-item"
							[routerLinkActive]="['active']">
							<a class="sidebar-link" [routerLink]="[appRoutes.SuperAdmins.LIST]">
								<i class="fa-regular fa-user-tie me-2"></i>
								Super Admins
							</a>
						</li>
						<li *requiresRole="[roles.ADMIN, roles.SUPER_ADMIN]"
							class="sidebar-item"
							[routerLinkActive]="['active']">
							<a class="sidebar-link" [routerLink]="[appRoutes.Admins.LIST]">
								<i class="fa-regular fa-user-shield me-2"></i>
								RR Admins
							</a>
						</li>
						<li *requiresRole="[roles.ADMIN, roles.SUPER_ADMIN]"
							class="sidebar-item"
							[routerLinkActive]="['active']">
							<a class="sidebar-link" [routerLink]="[appRoutes.BillingAdmins.LIST]">
								<i class="fa-regular fa-chart-user me-2"></i>
								Billing Admins
							</a>
						</li>
						<li *requiresRole="[roles.ADMIN, roles.SUPER_ADMIN]"
							class="sidebar-item"
							[routerLinkActive]="['active']">
							<a class="sidebar-link" [routerLink]="[appRoutes.CollectionAdmins.LIST]">
								<i class="fa-regular fa-chart-user me-2"></i>
								Collection Admins
							</a>
						</li>
						<li class="sidebar-item" *requiresPermission="permissions.User.LIST" [routerLinkActive]="['active']">
							<a class="sidebar-link" [routerLink]="[appRoutes.CompanyAdmins.LIST]">
								<i class="fa-regular fa-users-gear me-2"></i>
								Company Admins
							</a>
						</li>
						<li class="sidebar-item" *requiresPermission="permissions.User.LIST" [routerLinkActive]="['active']">
							<a class="sidebar-link" [routerLink]="[appRoutes.Drivers.LIST]">
								<i class="fa-regular fa-truck-tow me-2"></i>
								Drivers
							</a>
						</li>
						<li *requiresRole="[roles.ADMIN, roles.SUPER_ADMIN]" class="sidebar-item"  [routerLinkActive]="['active']">
							<a class="sidebar-link" [routerLink]="[appRoutes.Cleaners.LIST]">
								<i class="fa-regular fa-soap me-2"></i>
								Cleaners
							</a>
						</li>
					</ul>
				</li>
				<li *requiresRole="[roles.SUPER_ADMIN]" class="sidebar-item"  [class.active]="isActive(appRoutes.Settings.ADD)">
					<a class="sidebar-link" [routerLink]="[appRoutes.Settings.ADD]" >
						<i class="fa-light fa-money-check-dollar-pen me-2"></i>
						Settings
					</a>
				</li>
				<li class="sidebar-item mt-5">
					<a class="sidebar-link" [routerLink]="['/login']" (click)="logout()">
						<i class="far fa-sign-out fa-lg h-flip align-middle" data-feather="list"></i> <span class="align-middle">Logout</span>
					</a>
				</li>
			</ul>
		</div>
	</nav>
</div>

