<div *ngIf="error" class="alert alert-danger mt-2" role="alert">
    {{error?.message}}
</div>
<form [formGroup]="form" class="signup-form flex-fill">
    <div class="row">
        <div [class]="colSize">
            <div class="form-group">
                <label for="firstName" [class.error-label]="isFieldInvalid('firstName')">First Name</label>
                <input formControlName="firstName" type="text" name="firstName" id="firstName" placeholder="First Name"
                       [class.is-invalid]="isFieldInvalid('firstName')" class="form-control">
                <div class="invalid-feedback">
                    Please provide a name
                </div>
            </div>
        </div>
        <div [class]="colSize">
            <div class="form-group">
                <label for="lastName" [class.error-label]="isFieldInvalid('lastName')">Last Name</label>
                <input type="text" name="lastName" id="lastName" formControlName="lastName" placeholder="Last Name"
                       [class.is-invalid]="isFieldInvalid('lastName')" class="form-control">
                <div class="invalid-feedback">
                    Please provide a last name
                </div>
            </div>
        </div>
        <div [class]="colSize">
            <div class="form-group">
                <label for="phone" [class.error-label]="isFieldInvalid('phone')">Phone</label>
                <input type="text" name="phone" id="phone" formControlName="phone" placeholder="Phone"
                       [class.is-invalid]="isFieldInvalid('phone')" class="form-control error-edge">
                <div class="invalid-feedback">
                    Please provide a phone
                </div>
            </div>
        </div>
        <div [class]="colSize">
            <div class="form-group">
                <label for="email" [class.error-label]="isFieldInvalid('email')">Email</label>
                <input autocomplete="email" type="text" name="email" id="email" formControlName="email"
                       placeholder="Email"
                       [class.is-invalid]="isFieldInvalid('email')" class="form-control">
                <div class="invalid-feedback">
                    Please provide email
                </div>
            </div>
        </div>
        <div [class]="colSize" *ngIf=password>
            <div class="form-group">
                <label for="password" [class.error-label]="isFieldInvalid('password')">Password</label>
                <input autocomplete="current-password" type="password" name="password" id="password"
                       formControlName="password"
                       [class.is-invalid]="isFieldInvalid('password')" class="form-control" placeholder="Password">
                <div class="invalid-feedback">
                    Please provide a password
                </div>
                <div class="mt-4">
                    <password-strength-meter [password]="form.controls.password.value"></password-strength-meter>
                </div>
            </div>
        </div>

        <div [class]="colSize" *ngIf="showRoleSelection">
            <div class="form-group">
                <label for="role">Role</label>
                <select id="role" class="custom-select" formControlName="role"
                        [class.is-invalid]="isFieldInvalid('role')">
                    <option value="admin">Admin</option>
                    <option value="staff">Staff</option>
                    <option value="dealer">Dealer</option>
                </select>
                <div class="invalid-feedback">
                    Please provide a role
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2 justify-content-end">
        <div [class]="thirdActionName ? 'col-12 col-md-3' : colSize">
            <button *ngIf="thirdActionName" class="btn btn-third btn-block text-uppercase mb-3" (click)="thirdAction()">
                <span *ngIf="thirdActionClass"><i [class]="thirdActionClass"></i></span>
                {{thirdActionName}}
            </button>
        </div>
        <div [class]="secActionName ? 'col-12 col-md-3' : colSize">
            <button *ngIf="secActionName" class="btn btn-secondary btn-block text-uppercase mb-3"
                    (click)="secondaryAction()">
                {{secActionName}}
            </button>
        </div>
        <div [class]="secActionName ? 'col-12 col-md-3' : colSize">
            <button class="btn btn-primary btn-block text-uppercase mb-3" (click)="primaryAction()"
                    [disabled]="loading">
        <span *ngIf="loading">
          <i class="fad fa-spinner-third fa-2x fa-spin"></i>
        </span>
                <span *ngIf="!loading">
          {{actionName}}
        </span>
            </button>
        </div>
    </div>
</form>
