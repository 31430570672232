import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from 'src/app/models/user';
import {Observable} from 'rxjs';
import {filter, tap} from "rxjs/operators";

@Component({
    selector: 'user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
    @Input()
    password = false;
    @Input()
    colSize = 'col-12 col-md-6';

    @Input() actionName = 'Submit';
    @Output() onPrimaryAction = new EventEmitter<any>();

    @Input() secActionName = null;
    @Output() onSecAction = new EventEmitter<any>();

    @Input() thirdActionName = null;
    @Input() thirdActionClass = null;
    @Output() onThirdAction = new EventEmitter<any>();

    @Input()
    user$: Observable<User>;
    user: User;

    @Input()
    loading: boolean = false;

    @Input()
    showRoleSelection = false;

    form: FormGroup;
    error = null;

    constructor(private fb: FormBuilder) {

    }

    ngOnInit(): void {
        const group = {
            firstName: ['', Validators.required],
            lastName: [''],
            email: ['', Validators.compose([Validators.required, Validators.email])],
            phone: [''],
            password: undefined,
            role: undefined
        };
        if (this.password) {
            group.password = ['', Validators.required];
        }
        if (this.showRoleSelection) {
            group.role = (() => {
                const ctrl = this.fb.control('', Validators.required);
                const originalFunc = ctrl.setValue;
                ctrl.setValue = (value, options) => {
                    const newValue = value?.role || value;
                    originalFunc.call(ctrl, newValue, options);
                }
                return ctrl;
            })();
        }
        this.form = this.fb.group(group);
        this.patchUser();
    }

    isFieldInvalid(field: string): boolean {
        return this.form.get(field).invalid
            && (this.form.get(field).dirty
                || this.form.get(field).touched);
    }

    checkControls(): boolean {
        if (this.form.invalid) {
            const controls = Object.entries(this.form.controls);
            controls.forEach((control) => {
                if (control[1].errors) {
                    control[1].markAsTouched();
                }
            });
            return false;
        }
        return true;
    }

    getPayload(): object {
        return this.form.value;
    }

    patchUser(): void {
        this.user$.pipe(
            filter(u => !!u),
            tap(user => {
                this.user = user;
                this.form.patchValue(user);
            })
        ).subscribe();
    }

    primaryAction(): void {
        if (!this.checkControls()) return;
        this.onPrimaryAction.emit(this.getPayload());
    }

    secondaryAction(): void {
        this.onSecAction.emit();
    }

    thirdAction(): void {
        this.onThirdAction.emit(this.user);
    }

    get isValid() {
        return this.form.valid;
    }

    get f() {
        return this.form.controls;
    }

}
