import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {UserService} from 'src/app/services/user.service';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {NavService} from 'src/app/services/nav.service';
import {AppRoutes} from '../../../helpers/app-routes';
import {Permissions, Roles} from 'src/app/helpers/permissions';
import {User} from '../../../models/user';
import {QAFilterFields, QualityAssuranceStatus, RemediationStatus} from 'src/app/models/remediation-report';
import {isActive} from '../../../models/shared';
import {SpillReportStatus} from "../../../models/spill-report";
import {StatisticalReportType} from "../../../models/reports";

@Component({
	selector: 'navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

	appRoutes = AppRoutes;
	loggedIn = false;
	show = false;
	screenWidth: any;
	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription;
	permissions = Permissions;
	dashboardToggle = false;
	user: User;
	roles = Roles;
	billingParams = {};
	remediationStatus = RemediationStatus;
	qualityAssuranceStatus = QualityAssuranceStatus;
	spillStatus = SpillReportStatus;
	isActive = isActive;
	statReportType = StatisticalReportType;

	@Output() close: EventEmitter<void> = new EventEmitter();

	constructor(private route: ActivatedRoute,
				private authService: AuthService,
				private userService: UserService,
				private router: Router,
				public navService: NavService) {
	}

	ngOnInit(): void {
		this.userService.getCurrentUserAsync().subscribe(user => {
			this.user = user;
			this.loggedIn = this.authService.authCheck();
		});
		this.checkScreenSize();
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => this.checkScreenSize());
		//pre apply all cost recovery filters
		this.billingParams[`filters[${QAFilterFields.PICTURES_STATUS}]`] = QualityAssuranceStatus.PASSED;
		this.billingParams[`filters[${QAFilterFields.LOCATION_STATUS}]`] = QualityAssuranceStatus.PASSED;
		this.billingParams[`filters[${QAFilterFields.NARRATIVE_STATUS}]`] = QualityAssuranceStatus.PASSED;
	}

	checkScreenSize(): void {
		this.screenWidth = window.innerWidth;
	}

	navToggle(){
		this.dashboardToggle = !this.dashboardToggle;
	}

	logout(): void {
		this.authService.logout();
	}
}
