import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class NavService {
	isCollapsed = false;

	constructor() {
	}

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
	}

}
