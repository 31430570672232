import {InjectionToken} from '@angular/core';
import {TypeaheadAdapter} from './index';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import * as _ from 'lodash';
import {FilterField, OptionValue} from 'src/app/models/filters';

export class PropertyTypeaheadAdapter implements TypeaheadAdapter<OptionValue> {
	currentFilter: FilterField;
	filterFields: FilterField[];
	focus$ = new Subject<string>();

	constructor() {
	}

	resultFormatter(result: OptionValue): string {
		return result.label;
	}

	inputFormatter(input: OptionValue): string {
		return input.label;
	}

	search = (text$: Observable<string>): Observable<OptionValue[]> => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

		return merge(debouncedText$, this.focus$).pipe(
			map(search => {
				const filterOptions = this.getFilterOptions(search);
				if (!filterOptions) return;
				const lowerTxt = search.toLowerCase();
				return lowerTxt.length == 0 ? filterOptions.slice(0, 10)
					: filterOptions.filter(v => v.label.toLowerCase().indexOf(lowerTxt) > -1).slice(0, 10);
			})
		);
	}

	getFilterOptions(search: string): any[] {
		const filterOptions = _.find(this.filterFields, ['label', this.currentFilter?.label])?.options;
		if (filterOptions)
			return filterOptions
		if (search.length > 0)
			return [{label: search, value: search}];
		return [];
	}

}

export const PROPERTY_SEARCH = new InjectionToken<PropertyTypeaheadAdapter>('property search typeahead', {
	providedIn: 'root',
	factory: () => {
		return new PropertyTypeaheadAdapter();
	}
})
