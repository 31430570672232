<div class="modal-header" *ngIf="title">
    <h5 class="modal-title">{{ title }}</h5>
</div>

<div class="modal-body">
    <p>{{ message }}</p>
</div>

<div class="modal-footer">
    <button class="btn btn-secondary" (click)="onNegativeAction()">
        {{ negativeText }}
    </button>

    <button class="btn btn-danger" (click)="onPositiveAction()">
        {{ positiveText }}
    </button>
</div>
