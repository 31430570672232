<div class="row px-0 pb-4 text-md-left text-center">
    <div class="col-12" [class]="secCtaText ? 'col-md-8' : 'col-md-10'">
        <h3 class="">
            {{ title }}
            <span>
        <i class="fal fa-question-circle ml-2 pointer"></i>
      </span>
        </h3>
    </div>
    <div class="col-6 col-md-2 mt-md-0 mt-2" *ngIf="permission && secCtaText">
        <ng-container *requiresPermission="permission">
            <button *ngIf="ctaText" class="btn btn-secondary btn-block"
                    (click)="secCtaClicked.emit()">
                <i *ngIf="ctaIco" [class]="ctaIco"></i>
                {{ secCtaText }}
            </button>
        </ng-container>
    </div>
    <div class="col-6 col-md-2 mt-md-0 mt-2" *ngIf="permission">
        <ng-container *requiresPermission="permission">
            <button *ngIf="ctaText" class="btn btn-primary btn-block"
                    (click)="ctaClicked.emit()">
                <i *ngIf="secCtaIco" [class]="secCtaIco"></i>
                {{ ctaText }}
            </button>
        </ng-container>
    </div>
</div>
