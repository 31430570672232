import * as _ from "lodash";
import {
	DateOptionValue,
	FIELD_TYPES,
	FilterField,
	OptionValue,
} from "./filters";
import { FileModel } from "./file";
import * as moment from "moment";

export enum InvoiceStatus {
	NONE = "none",
	DENIED = "denied",
	REQUESTED_INFO = "requested_info",
	MAILED = "mailed",
	COMPLETE = "complete",
	PARTIAL_PAYMENT = "partial_payment",
	PRINT_READY = "print_ready",
	READY_TO_BILL = "ready_to_bill",
	RE_QA_REQUIRED = "re_qa_required",
	FAXED = "faxed",
	IN_COMMUNICATION = "in_communication",
	STOPPED_PAYMENT = "stopped_payment",
	WITHDRAWN = "withdrawn",
	STUCK = "stuck",
	NOT_BILLABLE = "not_billable",
	DUPLICATE = "duplicate",
	NO_POLICY_FOUND = "no_policy_found",
	ITEMIZED_BILL = "itemized_bill",
	ATTENTION = "attention",
	NEEDS_W9 = "needs_w9",
	NO_CLAIM_FILED = "no_claim_filed",
	SECOND_INVOICE_MAILED = "second_invoice_mailed",
	SIXTY_DAYS_PAST_DUE = "sixty_days_past_due",
	THIRD_INVOICE_MAILED = "third_invoice_mailed",
	C_AND_D = "c&d",
	EMAILED = "emailed",
	CALL_FOR_CLAIM = "call_for_claim",
	FINAL = "final",
	CALL_FOR_THIRD_PARTY = "call_for_third_party",
	NEEDS_TO_BE_FAXED = "needs_to_be_faxed",
	NEEDS_TO_BE_EMAILED = "needs_to_be_emailed",
	BEING_WORKED_ON = "being_worked_on",
	SCAN = "scan",
}

export enum CollectionInvoiceStatus {
	NONE = "new",
	ND_VM = "1st_VM",
	N_D_VM = "2nd_VM",
	FINAL_ATTEMPT = "Final_Attempt",
	PAYMENT_ISSUED = "Payment_Issued",
	DENIAL_EN_ROUTE = "Denial_En_Route",
	AWAITING_SUPERVISOR_APPROVAL = "Awaiting_Supervisor_Approval",
	EMAILED = "Emailed",
	REISSUED = "Reissued",
	CLAIM_INFO_FOUND = "Claim_Info_Found"
}

export enum CollectionStatusLabel {
	NONE = "New",
	ND_VM = "1st VM",
	N_D_VM = "2nd VM",
	FINAL_ATTEMPT = "Final Attempt",
	PAYMENT_ISSUED = "Payment Issued",
	DENIAL_EN_ROUTE = "Denial En Route",
	AWAITING_SUPERVISOR_APPROVAL = "Awaiting Supervisor Approval",
	EMAILED = "Emailed",
	REISSUED = "Reissued",
	CLAIM_INFO_FOUND = "Claim Info Found"	
}

export enum InvoiceStatusLabel {
	NONE = "New",
	DENIED = "Denied",
	REQUESTED_INFO = "Requested Info",
	MAILED = "Mailed",
	COMPLETE = "Complete",
	PARTIAL_PAYMENT = "Partial Payment",
	PRINT_READY = "Print Ready",
	READY_TO_BILL = "Ready to Bill",
	RE_QA_REQUIRED = "Re QA Required",
	FAXED = "Faxed",
	IN_COMMUNICATION = "In Communication",
	STOPPED_PAYMENT = "Stopped Payment",
	WITHDRAWN = "Withdrawn",
	STUCK = "Stuck",
	NOT_BILLABLE = "Not Billable",
	DUPLICATE = "Duplicate",
	NO_POLICY_FOUND = "No Policy Found",
	ITEMIZED_BILL = "Itemized Bill",
	ATTENTION = "Attention",
	NEEDS_W9 = "Needs W9",
	NO_CLAIM_FILED = "No Claim Filed",
	SECOND_INVOICE_MAILED = "Second Mailed",
	SIXTY_DAYS_PAST_DUE = "Sixty Days Past Due",
	THIRD_INVOICE_MAILED = "Third Mailed",
	C_AND_D = "C&D",
	EMAILED = "Emailed",
	CALL_FOR_CLAIM = "Call for Claim",
	FINAL = "Final",
	CALL_FOR_THIRD_PARTY = "Call for Third Party",
	NEEDS_TO_BE_FAXED = "Needs to be Faxed",
	NEEDS_TO_BE_EMAILED = "Needs to be Emailed",
	BEING_WORKED_ON = "Being Worked On",
	SCAN = "Scaned"
}

export const INVOICE_STATUS_OPTS = [
	{ label: InvoiceStatusLabel.NONE, value: InvoiceStatus.NONE },
	{ label: InvoiceStatusLabel.DENIED, value: InvoiceStatus.DENIED },
	{
		label: InvoiceStatusLabel.REQUESTED_INFO,
		value: InvoiceStatus.REQUESTED_INFO,
	},
	{ label: InvoiceStatusLabel.MAILED, value: InvoiceStatus.MAILED },
	{ label: InvoiceStatusLabel.COMPLETE, value: InvoiceStatus.COMPLETE },
	{
		label: InvoiceStatusLabel.PARTIAL_PAYMENT,
		value: InvoiceStatus.PARTIAL_PAYMENT,
	},
	{ label: InvoiceStatusLabel.PRINT_READY, value: InvoiceStatus.PRINT_READY },
	// {
	// 	label: InvoiceStatusLabel.READY_TO_BILL,
	// 	value: InvoiceStatus.READY_TO_BILL,
	// },
	{
		label: InvoiceStatusLabel.RE_QA_REQUIRED,
		value: InvoiceStatus.RE_QA_REQUIRED,
	},
	{ label: InvoiceStatusLabel.FAXED, value: InvoiceStatus.FAXED },
	// {
	// 	label: InvoiceStatusLabel.IN_COMMUNICATION,
	// 	value: InvoiceStatus.IN_COMMUNICATION,
	// },
	{
		label: InvoiceStatusLabel.STOPPED_PAYMENT,
		value: InvoiceStatus.STOPPED_PAYMENT,
	},
	{ label: InvoiceStatusLabel.WITHDRAWN, value: InvoiceStatus.WITHDRAWN },
	{ label: InvoiceStatusLabel.STUCK, value: InvoiceStatus.STUCK },
	{ label: InvoiceStatusLabel.NOT_BILLABLE, value: InvoiceStatus.NOT_BILLABLE },
	// { label: InvoiceStatusLabel.DUPLICATE, value: InvoiceStatus.DUPLICATE },
	{
		label: InvoiceStatusLabel.NO_POLICY_FOUND,
		value: InvoiceStatus.NO_POLICY_FOUND,
	},
	// {
	// 	label: InvoiceStatusLabel.ITEMIZED_BILL,
	// 	value: InvoiceStatus.ITEMIZED_BILL,
	// },
	{ label: InvoiceStatusLabel.ATTENTION, value: InvoiceStatus.ATTENTION },
	// { label: InvoiceStatusLabel.NEEDS_W9, value: InvoiceStatus.NEEDS_W9 },
	{
		label: InvoiceStatusLabel.NO_CLAIM_FILED,
		value: InvoiceStatus.NO_CLAIM_FILED,
	},
	{
		label: InvoiceStatusLabel.SECOND_INVOICE_MAILED,
		value: InvoiceStatus.SECOND_INVOICE_MAILED,
	},
	// {
	// 	label: InvoiceStatusLabel.SIXTY_DAYS_PAST_DUE,
	// 	value: InvoiceStatus.SIXTY_DAYS_PAST_DUE,
	// },
	{
		label: InvoiceStatusLabel.THIRD_INVOICE_MAILED,
		value: InvoiceStatus.THIRD_INVOICE_MAILED,
	},
	{
		label: InvoiceStatusLabel.C_AND_D,
		value: InvoiceStatus.C_AND_D,
	},
	{
		label: InvoiceStatusLabel.EMAILED,
		value: InvoiceStatus.EMAILED,
	},
	{
		label: InvoiceStatusLabel.CALL_FOR_CLAIM,
		value: InvoiceStatus.CALL_FOR_CLAIM,
	},
	{
		label: InvoiceStatusLabel.FINAL,
		value: InvoiceStatus.FINAL,
	},
	{
		label: InvoiceStatusLabel.CALL_FOR_THIRD_PARTY,
		value: InvoiceStatus.CALL_FOR_THIRD_PARTY,
	},
	{
		label: InvoiceStatusLabel.NEEDS_TO_BE_FAXED,
		value: InvoiceStatus.NEEDS_TO_BE_FAXED,
	},
	{
		label: InvoiceStatusLabel.NEEDS_TO_BE_EMAILED,
		value: InvoiceStatus.NEEDS_TO_BE_EMAILED,
	},
	{
		label: InvoiceStatusLabel.BEING_WORKED_ON,
		value: InvoiceStatus.BEING_WORKED_ON,
	},
	{
		label: InvoiceStatusLabel.SCAN,
		value: InvoiceStatus.SCAN,
	},
];

export const INVOICE_FILTER_FIELDS: FilterField[] = [
	{
		label: "Insurance Company",
		property: "insurance_company",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
	},
	{
		label: "State",
		property: "incident_state",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
	},
	{
		label: "Invoice Status",
		property: "status",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
		options: INVOICE_STATUS_OPTS,
	},
	{
		label: "Cleaner",
		property: "cleaner",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
	},
	{
		label: "Billing Staff",
		property: "billing_staff",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
	},
	{
		label: "Collection Staff",
		property: "collection_staff",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
	},
];

export const INVOICE_SEARCH_FIELDS: FilterField[] = [
	{
		label: "Incident ID",
		property: "incident_number",
		type: FIELD_TYPES.NUMBER,
		isPublic: true,
	},
	{
		label: "Spill ID",
		property: "spillId",
		type: FIELD_TYPES.NUMBER,
		isPublic: true,
	},
	{
		label: "Driver Name",
		property: "driverName",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
	},
	{
		label: "Policy Holder Name",
		property: "policyHolder",
		type: FIELD_TYPES.TEXT,
		isPublic: true,
	},
	{
		label: "Policy Number",
		property: "policyNumber",
		type: FIELD_TYPES.NUMBER,
		isPublic: true,
	},
];

export const INVOICE_DATE_OPTIONS: DateOptionValue[] = [
	{
		label: "Remediation Date",
		value: "remediationDate",
		isPublic: true,
	},
	{
		label: "Invoice Created Date",
		value: "invoiceCreated",
		isPublic: true,
	},
	{
		label: "Invoice Last Sent Date",
		value: "invoiceSent",
		isPublic: true,
	},
	{
		label: "Incident Date",
		value: "incidentDate",
		isPublic: true,
	},
];

export const invoiceTabToBaseParams: Map<string, string[]> = new Map([
	["roadsideInvoices", ["filters[exclude_incident_state]"]],
	["texasInvoices", ["filters[incident_state]"]],
	["echoInvoices", ["filters[flag]"]],
	["mondayInvoices", ["monday"]],
]);

export enum InvoiceFields {
	STATUS = "status",
	USER = "userId",
}

export const INVOICE_BULK_ACTIONS: OptionValue[] = [
	{ label: "Invoice Status", value: InvoiceFields.STATUS },
	{ label: "Billing Staff", value: InvoiceFields.USER },
];

export class InvoiceBulkUpdateDto {
	fieldToUpdate!: string;
	newValue!: string;
	invoiceIds!: number[];
}

export class IInvoiceModel {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	totalAmount: number;
	status: InvoiceStatus | string;
	sentAt: Date | null;
	// expenses: ExpenseItemModel[];
	files: InvoiceFileModel[];
	history: HistoryModel[];
	additionalNotes: string;
	collection_status: CollectionInvoiceStatus | string
}

export class InvoiceModel implements IInvoiceModel {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	totalAmount: number;
	status: InvoiceStatus | string;
	sentAt: Date | null;
	files: InvoiceFileModel[];
	history: HistoryModel[];
	uri?: string;
	additionalNotes: string;
	incidentNumber?: string;
	insuranceCompany?: string;
	state?: string;
	cleaner?: string;
	driver?: string;
	incidentDate?: string;
	incident_number: string;
	collection_status: CollectionInvoiceStatus | string;
	collectionStaff?: any;

	constructor(invoice: IInvoiceModel) {
		_.assign(this, invoice);
		this.setHistory();
	}

	private setHistory(): void {
		this.history = _.map(this.history, (h: HistoryModel) => {
			const logField = _.find(INVOICE_LOG_FIELDS, ["field", h.field]);
			h.prevValueLabel =
				INVOICE_STATUS_OPTS.find((i) => i.value === h.prevValue)?.label ||
				h.prevValue;
			h.newValueLabel =
				INVOICE_STATUS_OPTS.find((i) => i.value === h.newValue)?.label ||
				h.newValue;
			return _.assign(h, logField);
		});
		this.history.sort((a, b) => b.id - a.id);
	}
}

export class InvoiceFileModel implements FileModel {
	id: number;
	storagePath: string;
	fileName: string;
	originalFilename: string;
	createdAt: Date;
	updatedAt: Date;
}

export enum InvoiceType {
	ROADSIDE = "rr",
	MONDAY = "monday",
}

export enum FileTags {
	INVOICE_FILES = "invoice_files",
}

export interface HistoryModel {
	createdAt: Date;
	field: string;
	id: number;
	newValue: string;
	prevValue: string;
	newValueLabel: string;
	prevValueLabel: string;
	updatedAt: string;
	userEmail: string;
	ico?: string;
	label?: string;
}
const INVOICE_LOG_FIELDS = [
	{
		field: "sentAt",
		label: "Invoice Last Sent",
		ico: "fa-envelope-circle-check",
	},
	{ field: "createdBy", label: "Invoice Created", ico: "fa-plus" },
	{ field: "status", label: "Status Updated", ico: "fa-file-invoice-dollar" },
	{
		field: "regeneratedBy",
		label: "Invoice Regenerated",
		ico: "fa-arrows-rotate",
	},
	{
		field: "invoice scanned",
		label: "Invoice Scanned",
		ico: "fa-barcode",
	},
];

export function getInvoiceStatus(
	status: InvoiceStatus,
	style: boolean = false
): string {
	if (status === InvoiceStatus.NONE) {
		return style ? "text-muted" : InvoiceStatusLabel.NONE;
	}
	if (status === InvoiceStatus.DENIED) {
		return style ? "text-danger" : InvoiceStatusLabel.DENIED;
	}
	if (status === InvoiceStatus.COMPLETE) {
		return style ? "text-success" : InvoiceStatusLabel.COMPLETE;
	}
	if (status === InvoiceStatus.REQUESTED_INFO) {
		return style ? "invoice-status-1" : InvoiceStatusLabel.REQUESTED_INFO;
	}
	if (status === InvoiceStatus.MAILED) {
		return style ? "invoice-status-2" : InvoiceStatusLabel.MAILED;
	}
	if (status === InvoiceStatus.PARTIAL_PAYMENT) {
		return style ? "invoice-status-3" : InvoiceStatusLabel.PARTIAL_PAYMENT;
	}
	if (status === InvoiceStatus.PRINT_READY) {
		return style ? "invoice-status-4" : InvoiceStatusLabel.PRINT_READY;
	}
	if (status === InvoiceStatus.READY_TO_BILL) {
		return style ? "invoice-status-5" : InvoiceStatusLabel.READY_TO_BILL;
	}
	if (status === InvoiceStatus.RE_QA_REQUIRED) {
		return style ? "invoice-status-6" : InvoiceStatusLabel.RE_QA_REQUIRED;
	}
	if (status === InvoiceStatus.FAXED) {
		return style ? "invoice-status-7" : InvoiceStatusLabel.FAXED;
	}
	if (status === InvoiceStatus.IN_COMMUNICATION) {
		return style ? "invoice-status-8" : InvoiceStatusLabel.IN_COMMUNICATION;
	}
	if (status === InvoiceStatus.STOPPED_PAYMENT) {
		return style ? "invoice-status-9" : InvoiceStatusLabel.STOPPED_PAYMENT;
	}
	if (status === InvoiceStatus.WITHDRAWN) {
		return style ? "invoice-status-10" : InvoiceStatusLabel.WITHDRAWN;
	}
	if (status === InvoiceStatus.STUCK) {
		return style ? "invoice-status-11" : InvoiceStatusLabel.STUCK;
	}
	if (status === InvoiceStatus.NOT_BILLABLE) {
		return style ? "invoice-status-12" : InvoiceStatusLabel.NOT_BILLABLE;
	}
	if (status === InvoiceStatus.DUPLICATE) {
		return style ? "invoice-status-13" : InvoiceStatusLabel.DUPLICATE;
	}
	if (status === InvoiceStatus.NO_POLICY_FOUND) {
		return style ? "invoice-status-14" : InvoiceStatusLabel.NO_POLICY_FOUND;
	}
	if (status === InvoiceStatus.ITEMIZED_BILL) {
		return style ? "invoice-status-15" : InvoiceStatusLabel.ITEMIZED_BILL;
	}
	if (status === InvoiceStatus.ATTENTION) {
		return style ? "invoice-status-16" : InvoiceStatusLabel.ATTENTION;
	}
	if (status === InvoiceStatus.NEEDS_W9) {
		return style ? "invoice-status-17" : InvoiceStatusLabel.NEEDS_W9;
	}
	if (status === InvoiceStatus.NO_CLAIM_FILED) {
		return style ? "invoice-status-18" : InvoiceStatusLabel.NO_CLAIM_FILED;
	}
	if (status === InvoiceStatus.SECOND_INVOICE_MAILED) {
		return style
			? "invoice-status-19"
			: InvoiceStatusLabel.SECOND_INVOICE_MAILED;
	}
	if (status === InvoiceStatus.SIXTY_DAYS_PAST_DUE) {
		return style ? "invoice-status-20" : InvoiceStatusLabel.SIXTY_DAYS_PAST_DUE;
	}
	if (status === InvoiceStatus.THIRD_INVOICE_MAILED) {
		return style
			? "invoice-status-21"
			: InvoiceStatusLabel.THIRD_INVOICE_MAILED;
	}
	if (status === InvoiceStatus.C_AND_D) {
		return style ? "invoice-status-22" : InvoiceStatusLabel.C_AND_D;
	}
	if (status === InvoiceStatus.EMAILED) {
		return style ? "invoice-status-23" : InvoiceStatusLabel.EMAILED;
	}
	if (status === InvoiceStatus.CALL_FOR_CLAIM) {
		return style ? "invoice-status-24" : InvoiceStatusLabel.CALL_FOR_CLAIM;
	}
	if (status === InvoiceStatus.FINAL) {
		return style ? "invoice-status-25" : InvoiceStatusLabel.FINAL;
	}
	if (status === InvoiceStatus.CALL_FOR_THIRD_PARTY) {
		return style
			? "invoice-status-26"
			: InvoiceStatusLabel.CALL_FOR_THIRD_PARTY;
	}
	if (status === InvoiceStatus.NEEDS_TO_BE_FAXED) {
		return style ? "invoice-status-27" : InvoiceStatusLabel.NEEDS_TO_BE_FAXED;
	}
	if (status === InvoiceStatus.NEEDS_TO_BE_EMAILED) {
		return style ? "invoice-status-28" : InvoiceStatusLabel.NEEDS_TO_BE_EMAILED;
	}
	if (status === InvoiceStatus.BEING_WORKED_ON) {
		return style ? "invoice-status-29" : InvoiceStatusLabel.BEING_WORKED_ON;
	}

	if (status === InvoiceStatus.SCAN) {
		return style ? "invoice-status-30" : InvoiceStatusLabel.SCAN;
	}

	return "";
}

export function getCollectionStatus(
	status: CollectionInvoiceStatus,
	style: boolean = false
): string {
	if (status === CollectionInvoiceStatus.NONE) {
		return style ? "text-muted" : CollectionStatusLabel.NONE;
	}
	if (status === CollectionInvoiceStatus.ND_VM) {
		return style ? "invoice-status-22" : CollectionStatusLabel.ND_VM;
	}
	if (status === CollectionInvoiceStatus.N_D_VM) {
		return style ? "invoice-status-23" : CollectionStatusLabel.N_D_VM;
	}
	if (status === CollectionInvoiceStatus.PAYMENT_ISSUED) {
		return style ? "invoice-status-24" : CollectionStatusLabel.PAYMENT_ISSUED;
	}
	if (status === CollectionInvoiceStatus.REISSUED) {
		return style ? "invoice-status-25" : CollectionStatusLabel.REISSUED;
	}
	if (status === CollectionInvoiceStatus.FINAL_ATTEMPT) {
		return style ? "invoice-status-26" : CollectionStatusLabel.FINAL_ATTEMPT;
	}
	if (status === CollectionInvoiceStatus.EMAILED) {
		return style ? "invoice-status-27" : CollectionStatusLabel.EMAILED;
	}
	if (status === CollectionInvoiceStatus.DENIAL_EN_ROUTE) {
		return style ? "invoice-status-28" : CollectionStatusLabel.DENIAL_EN_ROUTE;
	}
	if (status === CollectionInvoiceStatus.CLAIM_INFO_FOUND) {
		return style ? "invoice-status-29" : CollectionStatusLabel.CLAIM_INFO_FOUND;
	}
	if (status === CollectionInvoiceStatus.AWAITING_SUPERVISOR_APPROVAL) {
		return style ? "invoice-status-30" : CollectionStatusLabel.AWAITING_SUPERVISOR_APPROVAL;
	}

	return "";
}

export function invoiceStatusDaysCount(
	historyDetails: HistoryModel[] = []
): string {
	if (historyDetails.length === 0) return "NA";
	let mailedDate = null;
	let billingDetailsDate = null;
	let result = "NA";
	historyDetails.forEach((history: HistoryModel) => {
		if (history.newValue === "mailed") {
			mailedDate = moment(history.createdAt);
		}
		if (
			history.newValue === "complete" ||
			history.newValue === "denied" ||
			history.newValue === "partial_payment"
		) {
			billingDetailsDate = moment(history.createdAt);
		}
		if (mailedDate && billingDetailsDate) {
			if (billingDetailsDate.diff(mailedDate, "days") >= 0) {
				result = billingDetailsDate.diff(mailedDate, "days");
			} else {
				return "NA";
			}
		}
	});
	return result;
}
