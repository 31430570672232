<div
	class="row my-md-4 my-3"
	#collapse="ngbCollapse"
	[(ngbCollapse)]="isCollapsed"
	[animation]="false"
>
	<div class="col-12">
		<form [formGroup]="form">
			<div class="row">
				<div
					class="col-12 col-md-3 mb-md-0 mb-3"
					*ngIf="filterFields?.length > 0"
				>
					<div class="input-group">
						<div class="input-group-prepend">
							<div ngbDropdown class="d-inline-block" container="body">
								<button
									class="btn btn-outline-primary"
									id="filterSelect"
									ngbDropdownToggle
								>
									<span *ngIf="!propertyFilter?.currentFilter">
										Select Filter
									</span>
									<span *ngIf="propertyFilter?.currentFilter">
										{{ propertyFilter.currentFilter?.label }}:
									</span>
								</button>
								<div ngbDropdownMenu aria-labelledby="filterSelect">
									<a
										class="dropdown-item pointer"
										(click)="selectTypeaheadItem(filter)"
										*ngFor="let filter of filterFields"
									>
										<span class="fa-stack me-1" style="font-size: 0.8em">
											<i class="fal fa-square fa-stack-2x"></i>
											<strong *ngIf="filter.type == 'text'" class="fa-stack-1x">
												A
											</strong>
											<i
												*ngIf="filter.type == 'dropdown'"
												class="far fa-caret-down fa-stack-1x"
											></i>
											<i
												*ngIf="filter.type == 'boolean'"
												class="far fa-toggle-on fa-stack-1x"
											></i>
											<strong
												*ngIf="filter.type == 'number'"
												class="fa-stack-1x"
											>
												1
											</strong>
										</span>
										{{ filter.label }}
									</a>
								</div>
							</div>
						</div>
						<ng-template #rt let-r="result" let-t="term">
							<ngb-highlight
								[result]="propertyFilter.resultFormatter(r)"
								[term]="t"
							></ngb-highlight>
						</ng-template>
						<input
							#input
							id="filter-search"
							class="form-control"
							formControlName="propertyLookup"
							type="text"
							autocomplete="off"
							placeholder="Filter value"
							(selectItem)="selectFilterValue($event)"
							[ngbTypeahead]="propertyFilter.search"
							[resultTemplate]="rt"
							[inputFormatter]="propertyFilter.inputFormatter"
							(focus)="onFocus('')"
						/>
					</div>
				</div>
				<div
					*ngIf="dateOptions?.length > 0"
					class="col-12 col-md-4 mb-md-0 mb-3"
				>
					<div class="input-group">
						<div ngbDropdown class="input-group-prepend" container="body">
							<button
								class="btn btn-outline-primary"
								id="dateSelect"
								ngbDropdownToggle
							>
								<i
									class="far fa-calendar-alt me-1"
									*ngIf="!selectedDateField?.nullCheck"
								></i>
								<i
									class="far fa-calendar-times me-1"
									*ngIf="selectedDateField?.nullCheck"
								></i>
								<span *ngIf="selectedDateField">
									{{ selectedDateField?.label }}
								</span>
								<span *ngIf="!selectedDateField"> Select Date Filter </span>
							</button>
							<div
								class="dropdown-menu"
								ngbDropdownMenu
								aria-labelledby="filterSelect"
							>
								<a
									class="dropdown-item pointer"
									(click)="selectDateField(dateField)"
									*ngFor="let dateField of dateOptions"
								>
									<span class="fa-stack me-1" style="font-size: 0.8em">
										<i
											class="fal fa-calendar-alt fa-stack-2x me-1"
											*ngIf="!dateField?.nullCheck"
										></i>
										<i
											class="fal fa-calendar-times fa-stack-2x me-1"
											*ngIf="dateField?.nullCheck"
										></i>
									</span>
									{{ dateField.label }}
								</a>
							</div>
						</div>
						<input
							*ngIf="selectedDateField?.nullCheck"
							name="noDatePicker"
							class="form-control rounded-right"
							disabled
							placeholder="{{ selectedDateField.label }}"
						/>
						<input
							name="datepicker"
							class="form-control rounded-right"
							ngbDatepicker
							*ngIf="!selectedDateField?.nullCheck"
							#datepicker="ngbDatepicker"
							role="button"
							[readOnly]="true"
							(click)="datepicker.toggle()"
							[autoClose]="'outside'"
							(dateSelect)="onDateSelection($event)"
							[displayMonths]="2"
							[dayTemplate]="t"
							[value]="formatDateRange()"
							outsideDays="hidden"
							[startDate]="fromDate!"
						/>
						<ng-template #t let-date let-focused="focused">
							<span
								class="custom-day"
								[class.focused]="focused"
								[class.range]="isRange(date)"
								[class.faded]="isHovered(date) || isInside(date)"
								(mouseenter)="hoveredDate = date"
								(mouseleave)="hoveredDate = null"
							>
								{{ date.day }}
							</span>
						</ng-template>
					</div>
				</div>
				<div class="col-12 col-md-auto text-center ms-3">
					<a
						class="action-link secondary d-block d-md-inline-block"
						(click)="collapse.toggle()"
					>
						<i class="far fa-filter"></i>
						<span> Hide Filters</span>
						<i class="fal fa-chevron-up ms-2"></i>
					</a>
				</div>
			</div>
		</form>
	</div>
	<div class="col-12 mb-3 mb-md-0">
		<div
			*ngFor="let f of appliedFilters"
			class="badge badge-pill badge-primary me-2 mt-3 p-1"
		>
			<span>
				{{ f.label }}: <strong>{{ f.selectedOption?.label }}</strong>
				<i
					class="fal fa-times fa-lg pointer ms-3"
					(click)="removeFilter(f)"
				></i>
			</span>
		</div>
	</div>
</div>

<div class="row mt-md-3" @popOverState>
	<div class="col-12 col-md-4" *ngIf="isCollapsed && searchFields">
		<form [formGroup]="searchForm">
			<div class="input-group">
				<div class="input-group-prepend" ngbDropdown container="body">
					<button
						class="btn btn-outline-primary dropdown-toggle"
						type="button"
						id="dropdown"
						ngbDropdownToggle
					>
						<span>{{
							currentSearch ? currentSearch.label : "Select field"
						}}</span>
					</button>
					<div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdown">
						<a
							class="dropdown-item pointer"
							(click)="selectTypeaheadItem(filter, true)"
							*ngFor="let filter of searchFields"
						>
							<span class="fa-stack mr-2" style="font-size: 0.8em">
								<i class="fal fa-square fa-stack-2x"></i>
								<strong *ngIf="filter.type == 'text'" class="fa-stack-1x"
									>A</strong
								>
								<i
									*ngIf="filter.type == 'dropdown'"
									class="far fa-caret-down fa-stack-1x"
								></i>
								<i
									*ngIf="filter.type == 'boolean'"
									class="far fa-toggle-on fa-stack-1x"
								></i>
								<strong *ngIf="filter.type == 'number'" class="fa-stack-1x"
									>1</strong
								>
							</span>
							{{ filter.label }}
						</a>
					</div>
				</div>
				<input
					id="propertyLookup"
					class="form-control vin-search"
					formControlName="propertyLookup"
					type="text"
					autocomplete="off"
					[placeholder]="'Search by ' + currentSearch?.label"
					(keyup.enter)="search()"
				/>
				<div
					class="input-group-prepend clear pointer"
					(click)="clearSearch()"
					*ngIf="currentSearchValue?.length >= 1"
				>
					<span class="input-group-text h-100">
						<i class="fal fa-times-circle fa-lg"></i>
					</span>
				</div>
				<div class="input-group-append search pointer" (click)="search()">
					<span
						class="input-group-text h-100"
						[class.active]="currentSearchValue?.length >= 1"
					>
						<i class="far fa-search"></i>
					</span>
				</div>
			</div>
		</form>
	</div>
	<div
		class="col-12 col-md-auto text-center"
		*ngIf="
			filterFields?.length > 0 ||
			dateOptions?.length > 0 ||
			appliedFilters?.length > 0
		"
	>
		<a
			*ngIf="isCollapsed"
			class="action-link secondary d-block d-md-inline-block"
			(click)="collapse.toggle()"
		>
			<i class="far fa-filter"></i>
			<span> Show Filters </span>
			<i class="fal fa-chevron-down ms-2"></i>
		</a>
	</div>
	<div class="col-12 col-md-auto mb-md-0 mb-2 pt-1 text-center">
		<a
			class="action-link secondary d-block d-md-inline-block"
			(click)="clearAll(); collapse.toggle()"
			*ngIf="!isCollapsed"
		>
			<i class="fal fa-minus-circle"></i> Clear All
		</a>
	</div>
	<div class="col-12 col-md-auto mb-md-0 mb-2 pt-1 text-center">
		<a
			class="action-link secondary d-block d-md-inline-block"
			(click)="applyAll()"
			*ngIf="!isCollapsed"
		>
			<i class="fal fa-check-circle"></i> Apply
		</a>
	</div>
	<div [formGroup]="masterSearchForm" *ngIf="isMasterSearch">
		<div class="row mt-md-3 master-search-container">
			<input class="form-control vin-search" formControlName="masterPropertyLookup" type="text" autocomplete="off" placeholder="{{searchPlaceholder}}" (keyup)="masterSearch()"/>
		</div>
	</div>
</div>
