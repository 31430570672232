import {Role} from "../models/role";

interface PermissionDef {
    resource: string;
    field?: string;
    action: string;
}

function parsePermissionDef(permission: string): PermissionDef {
    const parts = permission.split(':');
    if (parts.length === 2) {
        return {
            resource: parts[0],
            action: parts[1]
        }
    } else if (parts.length === 3) {
        return {
            resource: parts[0],
            field: parts[1],
            action: parts[2]
        }
    }
    throw Error(`Cannot parse permission: ${permission}`)
}

export function hasPermission(roleDef: Role, permission: string): boolean {
    const permissionDef = parsePermissionDef(permission);
    const allowed: PermissionDef[] = roleDef?.allow.map(parsePermissionDef);
    const denied: PermissionDef[] = roleDef?.deny.map(parsePermissionDef);
    return !!allowed?.find(allowable => {
            return allowable.resource === permissionDef.resource
                && allowable.action === permissionDef.action
        })
        && !denied?.find(deniable => {
            return deniable.resource === permissionDef.resource
                && deniable.field === permissionDef.field
                && deniable.action === permissionDef.action;
        })
}
