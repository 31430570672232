import * as _ from 'lodash';
import {Role} from './role';
import {CompanyModel} from './company';
import {FIELD_TYPES, FilterField} from './filters';

export class User {
	id: number;
	email: string;
	password?: string;
	firstName: string;
	lastName: string;
	token?: string;
	state?: string; // invitation property used in add-recipient
	role?: Role;
	address?: string;
	latitude?: number;
	longitude?: number;
	companies?: CompanyModel[];

	constructor(user: any) {
		_.assign(this, user);
	}
}

export const USER_FILTER_FIELDS: FilterField[] = [
	{label: 'Companies', property: 'companies', type: FIELD_TYPES.TEXT, isPublic: true},
];

export interface IUserRoutes {
	route: string;
	role: string;
	icon: string;
}

export const UserRoutes: IUserRoutes[] = [
	{route: 'admins', role: 'Admins', icon: 'fa-regular fa-user-shield'},
	{route: 'billingAdmins', role: 'Billing Admins', icon: 'fa-regular fa-chart-user'},
	{route: 'collectionAdmins', role: 'Collection Admins', icon: 'fa-regular fa-chart-user'},
	{route: 'superAdmins', role: 'Super Admins', icon: 'fa-regular fa-user-tie'},
	{route: 'companyAdmins', role: 'Company Admins', icon: 'fa-regular fa-users-gear'},
	{route: 'drivers', role: 'Drivers', icon: 'fa-regular fa-truck-tow'},
	{route: 'cleaners', role: 'Cleaners', icon: 'fa-regular fa-soap'}
];
