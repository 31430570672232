import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Observable} from 'rxjs';
import {first, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {NgxDropzoneChangeEvent} from 'ngx-dropzone';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { getRemediationReportStatus, RemediationReportModel, RemediationStatus, remediationValueOptions, REMEDIATION_REPORT_VALIDATION_CONTROLS } from '../models/remediation-report';
import { FileItem, FileModel } from '../models/file';
import { ImageTags, SpillReportModel, ValidationControls } from '../models/spill-report';
import { User } from '../models/user';
import { Roles } from '../helpers/permissions';
import { OptionValue } from '../models/filters';
import { RemediationReportService } from '../services/remediation-service';
import { UserService } from '../services/user.service';
import { CLEANER_SEARCH, UserByRoleTypeaheadAdapter } from '../services/search/user-search';
import { validationState } from '../models/form/AddEditUserForm';
import { CustomValidators } from '../modules/shared/CustomValidators';
import { AppRoutes } from '../helpers/app-routes';
import * as SimpleLightbox from 'simple-lightbox';
import { untilDestroyed } from '@ngneat/until-destroy';
import { createCsvLink } from '../helpers/helpers';
import { SpillReportService } from '../services/spill-report.service';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'app-qr-scan',
  templateUrl: './qr-scan.component.html',
  styleUrls: ['./qr-scan.component.scss']
})
export class QRScanComponent implements OnInit {
	@ViewChild('downloadLink') downloadLink: ElementRef;
    id: string;
	remediationReport$: Observable<RemediationReportModel>;
	form: FormGroup;
	useCoordsInNarrative: boolean = false;
	reportId: number;
	toBeDeleted: FileItem;
	imageTags = ImageTags;
	validationControls: ValidationControls;
	currentUser: User;
	roles = Roles;
	getRemediationReportStatus = getRemediationReportStatus;
	remediationStatus = RemediationStatus;
	isUnsafeOrNSF: boolean;
	reportStatusOpts = _.cloneDeep(remediationValueOptions);
	selectedOpt: OptionValue;
	selectedReport: RemediationReportModel;
	optField: string;
	validationState = validationState;
	loading = true;	
	loadingPoliceReport: boolean = false;
	loadingStateReport: boolean = false;
	loadingItemizedBill: boolean = false;
	spill_report_id: number;
	invoiceId: number;
	policeReportLink:string = "";
	stateReportLink: string = "";
	private readonly refreshClick$ = new BehaviorSubject<void>(null);
	inputValue: number = null;
	invoiceIsExist: boolean= false;

	constructor(private remediationReportService: RemediationReportService,
				private route: ActivatedRoute,
				private formBuilder: FormBuilder,
				private toastService: ToastrService,
				private router: Router,
				private modalService: NgbModal,
				private userService: UserService,
				private spillReportService: SpillReportService,
				private invoiceService: InvoiceService,
				@Inject(CLEANER_SEARCH) public cleanerSearch: UserByRoleTypeaheadAdapter) {
	}

	ngOnInit(): void {
	   this.formSetUp();
	   
		this.currentUser = this.userService.getCurrentUser();
		// this.remediationReport$ = this.route.params.pipe(
		// 	map(params => params.invoiceId),
		// 	switchMap((invoiceId: number) => {
		// 		this.invoiceId = invoiceId;							
		// 		return this.refreshClick$.pipe(
		// 			switchMap(() => {
		// 				return this.remediationReportService.getRemediationInvoiceByReportId(invoiceId);
		// 			})
		// 		);
		// 	}),
		// 	tap((report: RemediationReportModel) => {
		// 		this.reportId = report.report_id;
		// 		this.spill_report_id = report.spill_report?.spill_report_id;				
		// 		this.isUnsafeOrNSF = report?.status === RemediationStatus.NSF_UNSAFE;
		// 		this.isUnsafeOrNSF ? this.nsfForm() : this.remediationReportForm();
		// 		this.form.patchValue({
		// 			...report,
		// 		});
		// 		this.getStateLinkBySpillId(this.spill_report_id);
		// 		this.getPoliceReportLine(this.spill_report_id);
		// 		this.loading = false;
		// 	}, err => this.toastService.error(err.message, `Failed to fetch remediation report.`)),
		// 	shareReplay()
		// );
		// this.remediationReport$.subscribe();
  	}

	handleClick(): void {
		console.log('invoiceId', this.inputValue);			
		this.remediationReport$ = this.route.params.pipe(
			map(params => params),
			switchMap(() => {
				this.invoiceId = this.inputValue;							
				return this.refreshClick$.pipe(
					switchMap(() => {
						return this.remediationReportService.getRemediationInvoiceByReportId(this.inputValue);
					})
				);
			}),
			tap((report: RemediationReportModel) => {
				this.invoiceIsExist = true;
				this.reportId = report.report_id;
				this.spill_report_id = report.spill_report?.spill_report_id;				
				this.isUnsafeOrNSF = report?.status === RemediationStatus.NSF_UNSAFE;
				this.isUnsafeOrNSF ? this.nsfForm() : this.remediationReportForm();
				this.form.patchValue({
					...report,
				});
				this.getStateLinkBySpillId(this.spill_report_id);
				this.getPoliceReportLine(this.spill_report_id);
				this.loading = false;
			}, err => this.toastService.error(err.message, `Failed to fetch remediation report.`)),
			shareReplay()
		);
		this.remediationReport$.subscribe();
	}

	getPoliceReportLine(spill_report_id:number): void {
		this.route.queryParams.pipe(
			first(),
			tap(_ => this.loadingPoliceReport = true),				
			untilDestroyed(this),
			switchMap(() => {
				return this.spillReportService.spillReportDownload(spill_report_id)
			}),				
			tap(
				(file) => {
				    this.policeReportLink = file['police_report_link'];
					this.loadingPoliceReport = false; 
				},
				(error) => {
					this.toastService.error(
						error?.message,
						"Failed to download police report"
					);
					this.loadingPoliceReport = false;
				}
			),
		).subscribe()
	}

	getStateLinkBySpillId(spill_report_id:number) : void {
		this.route.queryParams.pipe(
			first(),
			tap(_ => this.loadingStateReport = true),				
			untilDestroyed(this),
			switchMap(() => {
				return this.spillReportService.stateReportDownload(spill_report_id)
			}),				
			tap(
				(file) => {		
					this.stateReportLink = file['state_report_link'];
					this.loadingStateReport = false;
				},
				(error) => {
					this.toastService.error(
						error?.message,
						"Failed to download state report"
					);
					this.loadingStateReport = false;
				}
			),
		).subscribe();
	}

	formSetUp(): void {
		this.validationControls = REMEDIATION_REPORT_VALIDATION_CONTROLS;
		this.form = this.formBuilder.group({
			user: this.formBuilder.control('', Validators.required),
			remediation_date_str: this.formBuilder.control('', [Validators.required, CustomValidators.date]),
			remediation_time_str: this.formBuilder.control('', [Validators.required, CustomValidators.time]),
			hazmat_level: this.formBuilder.control('', [Validators.required, Validators.pattern(/[0-9]+/)]),
			additional_time_required: this.formBuilder.control('', Validators.required),
			stain_lifter_coats: this.formBuilder.control('', [Validators.required, Validators.pattern(/[0-9]+/)]),
			remediator_coats: this.formBuilder.control('', [Validators.required, Validators.pattern(/[0-9]+/)]),
			absorbent_pads: this.formBuilder.control('', [Validators.required, Validators.pattern(/[0-9]+/)]),
			bags_of_debris: this.formBuilder.control('', [Validators.required, Validators.pattern(/[0-9]+/)]),
			scene_narrative: this.formBuilder.control('', Validators.required),
			incident_narrative: this.formBuilder.control('', Validators.required),
			report_narrative: this.formBuilder.control('', Validators.required),
			areas_inspected: this.formBuilder.control('', Validators.required),
			additional_notes: this.formBuilder.control(''),
		});
	}

	remediationReportForm(){
		this.form.removeControl('incident_narrative');
		this.form.removeControl('areas_inspected');
	}

	nsfForm(){
		this.form.removeControl('hazmat_level');
		this.form.removeControl('additional_time_required');
		this.validationControls.consumables.formControls.forEach(control => {
			this.form.removeControl(control);
		});
		this.form.removeControl('scene_narrative');
	}

	update(saveProgress: boolean, remediationStatus: RemediationStatus) {
		this.loading = true;
		const remediationReport = this.form.value as RemediationReportModel;
		remediationReport.status = remediationStatus;
		if (!saveProgress) {
			if (this.form.invalid){
				this.form.markAllAsTouched();
				this.openAllTabs();
				this.toastService.error('', `Missing Required Fields`);
				return;
			}
		}
 		this.remediationReportService.update(this.reportId, {...remediationReport})
		.pipe(
			tap(_ => {
				this.toastService.success('Updated remediation report');
				this.loading = false;
				this.refresh();
			}, err => this.toastService.error(err.message, `Failed to update remediation report.`)),
			switchMap((result: RemediationReportModel) => {
				return this.router.navigate([AppRoutes.RemediationReport.LIST, result.report_id]);
			})
		).subscribe();
	}

	// onSelect($event: NgxDropzoneChangeEvent, tag: ImageTags) {
	// 	const file: File = $event.addedFiles[0];
	// 	this.remediationReportService.uploadImage({
	// 		reportId: this.reportId,
	// 		file,
	// 		fileTag: tag
	// 	}).pipe(
	// 		tap(_ => {
	// 			this.refresh();
	// 		})
	// 	).subscribe(_ => {
	// 		this.toastService.success('Image successfully Uploaded!');
	// 	});
	// }

	onFocus($event): void {
		this.cleanerSearch.focus$.next($event);
	}

	openModal(content: any, image: FileItem = null, tag: string = null) {
		if(image) {
			this.toBeDeleted = {
				uri: image.uri,
				tag,
				id: image.id
			}
		}
		this.modalService.open(content);
	}

	onRemove() {
		this.remediationReportService.deleteImage(this.reportId, this.toBeDeleted).pipe(
			tap(_ => {
				this.refresh();
				this.toastService.success('Image successfully deleted!');
			})
		).subscribe();
	}

	next(key: string): void {
		if (!this.validateControllerGroup(key)){
			this.setControllerGroupAsTouched(key);
			this.toastService.error('', `Missing Required Fields`);
			return;
		}
		const current = this.validationControls[key];
		const next = _.find(this.validationControls, {index: current.index + 1});
		current.show = false;
		next.show = true;
	}

	previous(key: string): void {
		const current = this.validationControls[key];
		const previous = _.find(this.validationControls, {index: current.index - 1});
		current.show = false;
		previous.show = true;
	}

	openAllTabs(): void {
		for (const key in this.validationControls) {
			this.validationControls[key].show = true;
		}
	}

	validateControllerGroup(key: string): boolean{
		const controls = this.validationControls[key];
		return controls.formControls.every(control => {
			return this.form.controls[control].valid === true;
		});
	}

	setControllerGroupAsTouched(key: string): void{
		const controls = this.validationControls[key];
		controls.formControls.forEach(control => {
			this.form.controls[control].markAsTouched();
		});
	}

	getReportStatusOpts(reportStatus: RemediationStatus){
		if (this.currentUser.role.role === Roles.ADMIN || this.currentUser.role.role === Roles.SUPER_ADMIN){
			return this.reportStatusOpts;
		}
		return this.reportStatusOpts.filter(option => {
			return option.value === reportStatus
				|| option.value === RemediationStatus.NSF_UNSAFE
				|| option.label === 'Confirm';
		});
	}

	dropChange(): void {
		this.resetConfirmation();
	}

	resetConfirmation(): void {
		this.selectedOpt = null;
		this.selectedReport = null;
		this.optField = null;
	}

	updateOption(option: OptionValue, report: RemediationReportModel, field: string, confirm: boolean = true): void {
		this.selectedOpt = option;
		this.selectedReport = report;
		this.optField = field;
		if (!confirm) {
			this.confirmOption();
		}
	}

	confirmOption(): void {
		this.selectedReport[this.optField] = this.selectedOpt.value;
		this.remediationReportService.update(
			this.selectedReport.report_id, {status: this.selectedReport?.status}
		).pipe(
			tap(_ => {
				this.refresh();
				this.resetConfirmation();
				this.toastService.success('Spill report status updated!');
			})
		).subscribe();
	}

	showPhoto(photo: FileItem, tag: ImageTags): void {
		let allPhotos;
		this.remediationReport$.subscribe(r => {
			allPhotos = r[tag];
		});
		const filtered = allPhotos.filter(vs => photo?.uri !== vs?.uri);
		const gallery = filtered.map(p => p.uri);
		gallery.unshift(photo.uri);
		SimpleLightbox.open({items: gallery});
	}

	generateNarratives(oldReport: RemediationReportModel): void {
		//reconcile new form values with the old spill report to creat an up to date narrative 
		const remediationReport = this.form.value as RemediationReportModel;
		remediationReport.spill_report = oldReport.spill_report;
		const sceneNarrative = this.getSceneNarrative(remediationReport);
		const reportNarrative = this.getReportNarrative(remediationReport);
		this.form.patchValue({
			scene_narrative: sceneNarrative,
			report_narrative: reportNarrative
		});
	}

	getSceneNarrative(report: RemediationReportModel) {
		return (
		  `We responded to a ${report.spill_report.vehicle_involved_in_MVA} car MVA for a ` +
		  `HAZMAT level ${report.hazmat_level} clean up. We discovered from an initial police report ` +
		  `that there could be hazardous fluids left behind that are currently contaminating the environment. ` +
		  `We drove to ${this.getLocTxt(report.spill_report)}  as specified on the report ` +
		  `and upon arrival, we identified vehicle operating fluids from the accident still on the scene.`
		);
	}
	  
	getReportNarrative(report: RemediationReportModel) {
		const steps = report?.hazmat_level == 1 ? 2 : 3;
		const pads = report?.hazmat_level == 1 ? "" : "absorption of residuals,";
		const padsTwo = report?.hazmat_level == 1
		? ""
		: `After giving the bioremediation process some time to dissolve all ` +
			`contaminants we soaked up the excess fluids with more absorbent pads and finished with a final coat of the bioremediation formula. `;
		const padsThree = report?.hazmat_level == 1
			? ""
			: `${report.absorbent_pads} absorbent pad(s) used to clean the scene and `;
		return (
		  `After identifying the hazardous contaminants at the incident scene, ` +
		  `we secured the area with our service vehicle, hazard lights, and ` +
		  `placed cones around the perimeter of the clean-up area in order to ` +
		  `remediate the liquid debris safely. We then cleared all physical ` +
		  `debris from the incident area and our next step was to remediate the ` +
		  `identified leaked fluids with a ${steps}-step process: stain removal, ${pads} and ` +
		  `bioremediation process. We started with treating the entire surface area by spraying `+ 
		  `${report?.remediator_coats} coat(s) of bioremediation solvent in order to neutralize `+
		  `all hydrocarbons from the spill. We then scrubbed the solvent over the affected area ` +
		  `with a commercial brush to permeate the pavement and leaked fluids. ` +
		  `The bioremediation solvent began foaming, showing a reaction to hazardous contaminants. ` +
		  `${padsTwo}` +
		  `The bioremediation solvent is designed to continue to break down the hazardous contaminants ` +
		  `for several months and as such, was left to continue remediating without further intervention. ` +
		  `All${padsThree} additional debris ` +
		  `found were placed into a disposal bag for final clean up and removal. ` +
		  `The scene was cleared at ${this.getLocTxt(report.spill_report)} and we left. `
		);
	}
	
	getLocTxt(sReport: SpillReportModel) {
		let location = `${sReport.street_name || "NEEDS STREET"} / ${sReport.cross_street_name || "NEEDS CROSS ST"} ` +
			`/ ${sReport.distance || "NEEDS DISTANCE"} - ${sReport.matric_option || "NEEDS MEASUREMENT"}`;
		if (this.useCoordsInNarrative) {
			location = `coordinates ${sReport.latitude?.toString().substring(0, 7)}, ${sReport.longitude?.toString().substring(0, 7)}`;
		}
		return location;
	}

	refresh(){
		this.refreshClick$.next();
	}

	getPdf(type: string) {
		  if(type === 'itemized_bill'){
			this.route.queryParams.pipe(
				first(),
				tap(_ => this.loadingItemizedBill = true),				
				untilDestroyed(this),
				switchMap(() => {
					return this.invoiceService.itemizedBillReportDownload(this.invoiceId)
				}),				
				tap(
					(file: Blob) => {						
						const url = window.URL.createObjectURL(file);
						this.downloadLink.nativeElement.href = url;
						this.downloadLink.nativeElement.download = 'itemized-bill-report.pdf';
						this.downloadLink.nativeElement.click();
						window.URL.revokeObjectURL(url);		
						
						this.loadingItemizedBill = false;
					},
					(error) => {
						this.toastService.error(
							error?.message,
							"Failed to download itemized bill report"
						);
						this.loadingItemizedBill = false;
					}
			    ),
			).subscribe();
		}
	}
}
