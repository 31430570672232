<div class="card_wrapper" *ngIf="!invoiceIsExist">	
	<div class="card_box">
	  <h5 class="card-title"> Please enter incident number to check the details</h5>
	  <div class="card_input">
		<input type="text" class="form-control" [(ngModel)]="inputValue"/>
	  </div>
	  <button type="button" class="btn btn-primary" (click)="handleClick()">Verify</button>
	</div>	
</div>

<div class="card" *ngIf="invoiceIsExist">
	
	<!-- <div class="card-header border-bottom">
		<h3 class="mt-2 d-inline-block">Remediation Report</h3>
	</div> -->
	<div  class="d-flex downloadBtns">
		<a *ngIf="!loadingPoliceReport" target="_blank" href="{{policeReportLink}}" class="action-link secondary d-md-inline-block">
			<i class="fal fa-cloud-download"></i> Download Police Report
		</a>
		<span *ngIf="loadingPoliceReport" class="spinner-border text-primary ml-1" role="status"></span>
		<a  *ngIf="!loadingStateReport" target="_blank" href="{{stateReportLink}}" class="action-link secondary d-md-inline-block">
			<i class="fal fa-cloud-download"></i> Download State Report
		</a> 
		<span *ngIf="loadingStateReport" class="spinner-border text-primary ml-1" role="status"></span>
		<a #downloadLink style="display: none;"></a>
		<button class="itemized-bill-btn" *ngIf="!loadingItemizedBill" (click)="getPdf('itemized_bill')"><i class="fal fa-cloud-download"></i> Download Itemized Bill</button> 
		<span *ngIf="loadingItemizedBill" class="spinner-border text-primary ml-1" role="status"></span>
	</div>
	<div class="card-body">
		<div [formGroup]="form">
			<div class="row">
				<div class="col-12 pointer" (click)="validationControls.generalInfo.show = !validationControls.generalInfo.show">
					<i class="d-inline-block fal fa-info-circle fa-lg me-2"></i>
					<h4 class="d-inline-block mt-3 pt-1 mb-3">General Information</h4>
					<h5 class="d-inline-block ms-2">
						<i class="fal fa-chevron-circle-down fa-lg ml-4" *ngIf="!validationControls.generalInfo.show"></i>
						<i class="fal fa-chevron-circle-up fa-lg ml-4" *ngIf="validationControls.generalInfo.show"></i>
					</h5>
					<hr/>
				</div>
			</div>
			<div class="row" *ngIf="validationControls.generalInfo.show">
				<!-- <div class="mb-3 col-md-3 col-xl-2" *ngIf="remediationReport$ | async; let report;">
					<label class="form-label">Spill ID</label>
					<p  class="d-block">
						{{report?.spill_report?.spill_report_id}}
					</p>
				</div> -->
				<div class="mb-3 col-md-3 col-xl-2" *ngIf="(remediationReport$ | async)?.spill_report?.incident_number as incidentNumber">
					<label class="form-label">Incident ID</label>
					<p>{{incidentNumber}}</p>
				</div>
				<div class="mb-3 col-md-3 col-xl-2" *ngIf="currentUser?.role?.role === roles.ADMIN || currentUser?.role?.role === roles.SUPER_ADMIN; else cleanerReadOnly">
					<label for="user" class="form-label">Cleaner</label>
					<input id="user" formControlName="user" type="text" class="form-control disabled"
						   autocomplete="off"
						   [readonly] = true
						   [ngbTypeahead]="cleanerSearch.search"
						   [resultFormatter]="cleanerSearch.resultFormatter"
						   [inputFormatter]="cleanerSearch.inputFormatter"
						   (focus)="onFocus('')"						   
						  >
					<!-- <div class="invalid-feedback">
						Please enter a Cleaner
					</div> -->
				</div>
				<!-- <div class="mb-3 col-md-3 col-xl-2">
					<label for="remediation_date_str" class="form-label">Remediation Date</label>
					<input id="remediation_date_str" formControlName="remediation_date_str" type="date" class="form-control"
						   [ngClass]="validationState(form.get('remediation_date_str'))">
					<div class="invalid-feedback">
						Please enter an Remediation Date
					</div>
				</div> -->
				<!-- <div class="mb-3 col-md-3 col-xl-2">
					<label for="remediation_time_str" class="form-label">Remediation Time</label>
					<input id="remediation_time_str" formControlName="remediation_time_str" type="time" class="form-control"
						   [ngClass]="validationState(form.get('remediation_time_str'))">
					<div class="invalid-feedback">
						Please enter an Remediation Time
					</div>
				</div> -->
				<!-- <div class="mb-3 col-md-3 col-xl-2" *ngIf="remediationReport$ | async; let report;">
					<label class="form-label"># Vehicles Involved</label>
					<p>{{report?.spill_report?.vehicle_involved_in_MVA}}</p>
				</div>
				<div class="mb-3 col-md-3 col-xl-2" *ngIf="!isUnsafeOrNSF">
					<label for="hazmat_level" class="form-label">Hazmat Level</label>
					<input id="hazmat_level" formControlName="hazmat_level" type="number" class="form-control"
						   [ngClass]="validationState(form.get('hazmat_level'))">
					<div class="invalid-feedback">
						Please enter a Hazmat Level
					</div>
				</div> -->
				<!-- <div class="mb-3 col-md-3 col-xl-2" *ngIf="!isUnsafeOrNSF">
					<label for="additional_time_required" class="form-label">Additional Time</label>
					<input id="additional_time_required" formControlName="additional_time_required" type="number" class="form-control"
						   [ngClass]="validationState(form.get('additional_time_required'))">
					<div class="invalid-feedback">
						Please enter any Additional Time Required
					</div>
				</div> -->
				<!-- <div class="mb-3 col-md-3 col-xl-2" *ngIf="(remediationReport$ | async)?.spill_report?.police_report_link as policeReportLink;">
					<label class="form-label">Police Report Link</label>
					<a class="d-block" [href]="policeReportLink" target="_blank">Police Report</a>
				</div> -->
				<!-- <div class="mb-3 col-md-3 col-xl-2" *ngIf="remediationReport$ | async; let report;">
					<label class="form-label">Status</label>
					<p *ngIf="currentUser?.role?.role !== roles.ADMIN && currentUser?.role?.role !== roles.SUPER_ADMIN"
					   [class]="getRemediationReportStatus(report?.status, true)">
						{{getRemediationReportStatus(report?.status)}}
					</p>
					<div *requiresRole="[roles.ADMIN, roles.SUPER_ADMIN]" ngbDropdown #reportDrop="ngbDropdown"
						[autoClose]="'outside'" (openChange)="dropChange()">
						<span class="status-container pointer" [class]="getRemediationReportStatus(report?.status, true)"
							id="conversionDropdown"
							ngbDropdownToggle>
							<i class="far fa-leaf fa-lg me-2"></i>
							<span>{{getRemediationReportStatus(report?.status)}} </span>
						</span>
						<div ngbDropdownMenu aria-labelledby="conversionDropdown">
							<ng-container
								*ngTemplateOutlet="approveOption; context: {report: report, options: getReportStatusOpts(report?.status), field: 'status', drop: reportDrop}">
							</ng-container>
						</div>
					</div>
				</div> -->
				<div class="row mt-4 mb-3" *ngIf="isUnsafeOrNSF">
					<div class="mb-3 col-md-6">
						<label for="incident_narrative" class="form-label">What kind of accident did you respond to?</label>
						<textarea id="incident_narrative" readonly formControlName="incident_narrative" class="form-control " readonly								  
								  placeholder="Examples: sideswipe, head on collision, backing out of parking spot, turning at a light, hit a deer, etc">
					    </textarea>						
					</div>
					<div class="mb-3 col-md-6">
						<label for="areas_inspected" class="form-label">Which area(s) did you check when you arrived on scene?</label>
						<textarea id="areas_inspected" formControlName="areas_inspected" class="form-control "	readonly							  
								  placeholder="Based on the incident narrative and information available on the accident report">
					    </textarea>
						
					</div>
					<div class="mb-3 col-md-6">
						<label for="additional_notes" class="form-label">Additional Notes</label>
						<textarea id="additional_notes" formControlName="additional_notes" class="form-control " readonly>
						</textarea>
					</div>
				</div>
				<div class="row mb-5" *ngIf="(remediationReport$| async)?.remediation_report_location?.length > 0">
					<label class="form-label mb-3">Remediation Location</label>
					<div *ngFor="let image of (remediationReport$| async)?.remediation_report_location" class="images-center-xs col-12 col-md-4 col-xl-3 col-xxl-2">
						<img class="images pointer" [src]="image?.uri" alt="" (click)="showPhoto(image, imageTags.REMEDIATION_REPORT_LOCATION)">
						<!-- <div class="text-center mt-3 delete-btn-container">
							<button class="btn btn-outline-danger delete-btn" (click)="openModal(deleteModal, image, imageTags.REMEDIATION_REPORT_LOCATION)">
								<i class="fal fa-trash-alt"></i>
							</button>
						</div> -->
					</div>
					<!-- <ngx-dropzone *ngIf="(remediationReport$| async)?.remediation_report_location.length < 5"
								   class="images mx-auto ms-md-2">
						<ngx-dropzone-label>Upload Image</ngx-dropzone-label>
					</ngx-dropzone> -->
				</div>
				<div class="row mb-5" *ngIf="isUnsafeOrNSF">
					<div *ngIf="(remediationReport$| async)?.scene_picture?.length > 0">
						<label class="form-label mb-3">Scene Pictures</label>
						<div *ngFor="let image of (remediationReport$| async)?.scene_picture" class="images-center-xs col-12 col-md-4 col-xl-2">
							<img class="images pointer" [src]="image?.uri" alt="" (click)="showPhoto(image, imageTags.SCENE_PICTURE)">
							<!-- <div class="text-center mt-3 delete-btn-container">
								<button class="btn btn-outline-danger delete-btn" (click)="openModal(deleteModal, image, imageTags.SCENE_PICTURE)">
									<i class="fal fa-trash-alt"></i>
								</button>
							</div> -->
						</div>
						<!-- <ngx-dropzone *ngIf="(remediationReport$| async)?.scene_picture.length < 5"
									  (change)="onSelect($event, imageTags.SCENE_PICTURE)" class="images mx-auto ms-md-2">
							<ngx-dropzone-label>Upload Image</ngx-dropzone-label>
						</ngx-dropzone> -->
					</div>
				</div>
				<!-- <div *ngIf="!isUnsafeOrNSF">
					<div class="col-12 col-md-2 mx-auto mb-5">
						<button class="btn btn-primary text-uppercase w-100"
								(click)="next('generalInfo')">
							Next
						</button>
					</div>
				</div> -->
			</div>
			<div class="row" *ngIf="!isUnsafeOrNSF">
				<div class="col-12 pointer" (click)="validationControls.consumables.show = !validationControls.consumables.show">
					<i class="d-inline-block fal fa-fill-drip fa-lg me-2"></i>
					<h4 class="d-inline-block mt-3 pt-1 mb-3">Consumables</h4>
					<h5 class="d-inline-block ms-2">
						<i class="fal fa-chevron-circle-down fa-lg ml-4" *ngIf="!validationControls.consumables.show"></i>
						<i class="fal fa-chevron-circle-up fa-lg ml-4" *ngIf="validationControls.consumables.show"></i>
					</h5>
					<hr/>
				</div>
			</div>
			<div class="row" *ngIf="validationControls.consumables.show && !isUnsafeOrNSF">
				<div class="mb-3 col-md-3 col-xl-2">
					<label for="stain_lifter_coats" class="form-label">Stain Lifter Coats</label>
					<input id="stain_lifter_coats" formControlName="stain_lifter_coats" type="text" class="form-control disabled">					
				</div>
				<div class="mb-3 col-md-3 col-xl-2">
					<label for="remediator_coats" class="form-label">Remediator Coats</label>
					<input id="remediator_coats" formControlName="remediator_coats" type="text" class="form-control disabled">					
				</div>
				<div class="mb-3 col-md-3 col-xl-2">
					<label for="absorbent_pads" class="form-label">Absorbent Pads</label>
					<input id="absorbent_pads" formControlName="absorbent_pads" type="text" class="form-control disabled">					
				</div>
				<div class="mb-3 col-md-3 col-xl-2">
					<label for="bags_of_debris" class="form-label">Bags of Debris</label>
					<input id="bags_of_debris" formControlName="bags_of_debris" type="text" class="form-control disabled">					
				</div>
				<!-- <div class="row mt-5">
					<div class="col-12 col-md-2 mb-5 d-inline-block ms-auto">
						<button class="btn btn-primary text-uppercase w-100 d-inline-block"
								(click)="previous('consumables')">
							Previous
						</button>
					</div>
					<div class="col-12 col-md-2 mb-5 d-inline-block me-auto">
						<button class="btn btn-primary text-uppercase w-100 d-inline-block"
								(click)="next('consumables')">
							Next
						</button>
					</div>
				</div> -->
			</div>
			<div class="row" *ngIf="!isUnsafeOrNSF">
				<div class="col-12 pointer" (click)="validationControls.narratives.show = !validationControls.narratives.show">
					<i class="d-inline-block fal fa-typewriter fa-lg me-2"></i>
					<h4 class="d-inline-block mt-3 pt-1 mb-3">Narratives</h4>
					<h5 class="d-inline-block ms-2">
						<i class="fal fa-chevron-circle-down fa-lg ml-4" *ngIf="!validationControls.narratives.show"></i>
						<i class="fal fa-chevron-circle-up fa-lg ml-4" *ngIf="validationControls.narratives.show"></i>
					</h5>
					<hr/>
				</div>
			</div>
			<div class="row" *ngIf="validationControls.narratives.show && !isUnsafeOrNSF">
				<div class="row mb-5">
					<div class="mb-3 col-md-6 col-xl-4">
						<label for="scene_narrative" class="form-label">Scene Narrative</label>
						<textarea id="scene_narrative" formControlName="scene_narrative" class="form-control"
							readonly	   rows="8">
						</textarea>						
					</div>
					<div class="mb-3 col-md-6 col-xl-4">
						<label for="report_narrative" class="form-label">Report Narrative</label>
						<textarea id="report_narrative" formControlName="report_narrative" class="form-control "
						   readonly	   rows="8">
						</textarea>
						
					</div>
					<div class="mb-3 col-md-3 col-xl-4">
						<label for="additional_notes" class="form-label">Additional Notes</label>
						<textarea id="additional_notes" formControlName="additional_notes" class="form-control " readonly>
						</textarea>
					</div>
					<!-- <div class="offset-md-5 col-12 col-md-2 my-2">
						<button class="btn btn-primary text-uppercase w-100 d-inline-block" (click)="openModal(narrativeModal)">
							Generate Narratives
						</button>
					</div> -->
				</div>
				<div class="row mb-5" *ngIf="(remediationReport$| async)?.before_remediation?.length>0">
					<label class="form-label mb-3">Before Remediation</label>
					<div *ngFor="let image of (remediationReport$| async)?.before_remediation" class="images-center-xs col-12 col-md-4 col-xl-3 col-xxl-2">
						<img class="images pointer" [src]="image?.uri" alt="" (click)="showPhoto(image, imageTags.BEFORE_REMEDIATION)">
						<!-- <div class="text-center mt-3 delete-btn-container">
							<button class="btn btn-outline-danger delete-btn" (click)="openModal(deleteModal, image, imageTags.BEFORE_REMEDIATION)">
								<i class="fal fa-trash-alt"></i>
							</button>
						</div> -->
					</div>
					<!-- <ngx-dropzone *ngIf="(remediationReport$| async)?.before_remediation.length < 5"
								  (change)="onSelect($event, imageTags.BEFORE_REMEDIATION)" class="images mx-auto ms-md-2">
						<ngx-dropzone-label>Upload Image</ngx-dropzone-label>
					</ngx-dropzone> -->
				</div>
				<div class="row mb-5" *ngIf="(remediationReport$| async)?.remediation_application?.length >0">
					<label class="form-label mb-3">Remediation Application</label>
					<div *ngFor="let image of (remediationReport$| async)?.remediation_application" class="images-center-xs col-12 col-md-4 col-xl-3 col-xxl-2">
						<img class="images pointer" [src]="image?.uri" alt="" (click)="showPhoto(image, imageTags.REMEDIATION_APPLICATION)">
						<!-- <div class="text-center mt-3 delete-btn-container">
							<button class="btn btn-outline-danger delete-btn" (click)="openModal(deleteModal, image, imageTags.REMEDIATION_APPLICATION)">
								<i class="fal fa-trash-alt"></i>
							</button>
						</div> -->
					</div>
					<!-- <ngx-dropzone *ngIf="(remediationReport$| async)?.remediation_application.length < 5"
								  (change)="onSelect($event, imageTags.REMEDIATION_APPLICATION)" class="images mx-auto ms-md-2">
						<ngx-dropzone-label>Upload Image</ngx-dropzone-label>
					</ngx-dropzone> -->
				</div>
				<div class="row mb-5" *ngIf="(remediationReport$| async)?.after_remediation_final?.length >0">
					<label class="form-label mb-3">After Remediation Final</label>
					<div *ngFor="let image of (remediationReport$| async)?.after_remediation_final" class="images-center-xs col-12 col-md-4 col-xl-3 col-xxl-2">
						<img class="images pointer" [src]="image?.uri" alt="" (click)="showPhoto(image, imageTags.AFTER_REMEDIATION_FINAL)">
						<!-- <div class="text-center mt-3 delete-btn-container">
							<button class="btn btn-outline-danger delete-btn" (click)="openModal(deleteModal, image, imageTags.AFTER_REMEDIATION_FINAL)">
								<i class="fal fa-trash-alt"></i>
							</button>
						</div> -->
					</div>
					<!-- <ngx-dropzone *ngIf="(remediationReport$| async)?.after_remediation_final.length < 5"
								  (change)="onSelect($event, imageTags.AFTER_REMEDIATION_FINAL)" class="images mx-auto ms-md-2">
						<ngx-dropzone-label>Upload Image</ngx-dropzone-label>
					</ngx-dropzone> -->
				</div>
				<!-- <div class="offset-md-5 col-12 col-md-2 my-5">
					<button class="btn btn-primary text-uppercase w-100 d-inline-block"
							(click)="previous('narratives')">
						Previous
					</button>
				</div> -->
			</div>

			<!-- <div class="row">
				<div class="col-12 pointer" (click)="validationControls.histories.show = !validationControls.histories.show">
					<i class="d-inline-block fa-regular fa-clock-rotate-left me-2"></i>
					<h4 class="d-inline-block mt-3 pt-1 mb-3">Remediation Report History</h4>
					<h5 class="d-inline-block ms-2">
						<i class="fal fa-chevron-circle-down fa-lg ml-4" *ngIf="!validationControls.histories.show"></i>
						<i class="fal fa-chevron-circle-up fa-lg ml-4" *ngIf="validationControls.histories.show"></i>
					</h5>
					<hr/>
				</div>
			</div> -->
			<!-- <div class="row" *ngIf="validationControls.histories.show">
				<div class="row mb-5">
					<div *ngIf="(remediationReport$| async)?.history?.length > 0; else emptyHistory">
						<div class="d-flex align-items-center justify-content-center h-100" *ngIf="loading">
							<div>
								<div id="loading"></div>
							</div>
						</div>
						<ng-container *ngIf="!loading">
							<div class="row py-4 py-md-2 d-flex align-items-center  history-container" *ngFor="let event of (remediationReport$ | async)?.history">
								<div class="col-md-1 col-3 text-md-center text-left stacked-icons">
							<span class="fa-stack fa-2x">
								<i class="fa-solid fa-circle fa-stack-2x" style="color: #f4f8fb;"></i>
								<i [class]="event.ico" class="fa-regular fa-stack-1x"></i>
							</span>
								</div>
								<div class="col-8 col-md-2 mb-md-0 mb-3" *ngIf="remediationReport$ | async; let report;">
									<strong>{{event.label}}</strong>
									<p>{{event.createdAt | localizedDate: report?.spill_report.incident_timezone:'f'}}</p>
								</div>
								<div class="col-5 col-md-3 text-md-left text-center" [ngSwitch]="event?.field">
								<span *ngSwitchCase="'regeneratedBy'">
									{{event.prevValueLabel | currency: 'USD'}}
								</span>
									<span *ngSwitchDefault>
									{{event.prevValueLabel | dateTimezone: 'f'}}
								</span>
								</div>
								<div class="col-2 col-md-1 text-comp text-center"><i class="fad fa-long-arrow-right fa-2x"></i>
								</div>
								<div class="col-5 col-md-3 text-md-left text-center" [ngSwitch]="event?.field">
								<span *ngSwitchCase="'regeneratedBy'">
									{{event.newValueLabel | currency: 'USD'}}
								</span>
									<span *ngSwitchDefault>
									{{event.newValueLabel | dateTimezone: 'f'}}
								</span>
								</div>
								<div class="col-12 col-md-2 text-md-left text-center mt-md-0 mt-3">
									{{event.userEmail}}
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</div>
<!-- <div class="row justify-content-end mt-3">
	<div class="col-12 col-md-2" *ngIf="remediationReport$ | async; let report;">
		<button class="btn btn-outline-primary text-uppercase w-100" (click)="update(true, report?.status)">Save Progress</button>
	</div>
	<div class="col-12 col-md-2 mt-4 mt-md-0" *ngIf="(currentUser?.role?.role !== roles.ADMIN && currentUser?.role?.role !== roles.SUPER_ADMIN) && !isUnsafeOrNSF">
		<button class="btn btn-outline-primary text-uppercase w-100" (click)="update(false, remediationStatus.SAVED)">Report Remediation</button>
	</div>
	<div class="col-12 col-md-2 mt-4 mt-md-0" *ngIf="(currentUser?.role?.role === roles.ADMIN || currentUser?.role?.role === roles.SUPER_ADMIN) && !isUnsafeOrNSF">
		<button class="btn btn-outline-primary text-uppercase w-100" (click)="update(false, remediationStatus.FINISHED)">Verify Remediation</button>
	</div>
</div> -->

<!-- <ng-template #deleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">
			<i class="fal fa-exclamation-triangle mr-2"></i>
			Delete Image
		</h4>
	</div>
	<div class="modal-body">
		<p><strong>Are you sure want to delete this image?</strong></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.close('close')">Close</button>
		<button type="button" class="btn btn-primary" (click)="onRemove();modal.close('close')">DELETE</button>
	</div>
</ng-template> -->

<ng-template #cleanerReadOnly>
	<div class="mb-3 col-md-3 col-xl-2" *ngIf="remediationReport$ | async; let report;">
		<label class="form-label">Cleaner</label>
		<p>{{report?.user?.firstName}} {{report?.user?.lastName}}</p>
	</div>
</ng-template>

<!-- <ng-template #emptyHistory>
	<empty-state [resource]="'Remediation Report History'"
				 [iconClass]="'fa-regular fa-clock-rotate-left fa-3x'"
				 [showSubtitle]="false"
	>
	</empty-state>
</ng-template> -->

<!-- <ng-template #approveOption let-options="options" let-report="report" let-field="field" let-drop="drop">
	<ng-container *ngFor="let option of options">
		<button ngbDropdownItem *ngIf="option.value"
				(click)="updateOption(option, report, field)">
			{{option.label}}
		</button>
		<div *ngIf="!option.value && selectedOpt" class="text-center confirmation-btns my-2" @animatedListItem>
			<div class="confirm-container text-danger d-inline-block me-3"
				 (click)="resetConfirmation();$event.stopPropagation(); drop.close();">
				<i class="fal fa-times"></i>
			</div>
			<div class="confirm-container text-success d-inline-block ml-3"
				 (click)="confirmOption();$event.stopPropagation(); drop.close();">
				<i class="fal fa-check"></i>
			</div>
		</div>
	</ng-container>
</ng-template> -->

<!-- <ng-template #narrativeModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">
			<i class="fa-light fa-pen me-2"></i> Generate Narratives
		</h4>
	</div>
	<div class="modal-body">
		<p>To automatically generate the naratives please make sure you have filled in all consumables. 
			This will generate new narratives with the latest info and overwrite the existing narratives.
		</p>
		<p>Please select the location type for the narrative.</p>
		<div class="form-check">
			<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" [value]="false" [(ngModel)]="useCoordsInNarrative">
			<label class="form-check-label" for="flexRadioDefault1">
			Street address
			</label>
		</div>
		<div class="form-check">
			<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" [value]="true" [(ngModel)]="useCoordsInNarrative">
			<label class="form-check-label" for="flexRadioDefault2">
				Coordinates
			</label>
		</div>
	</div>
	<div class="modal-footer" *ngIf="remediationReport$ | async; let report;">
		<button type="button" class="btn btn-secondary" (click)="modal.close('close')">Close</button>
		<button type="button" class="btn btn-primary" (click)="generateNarratives(report); modal.close('close');">GENERATE</button>
	</div>
</ng-template> -->
