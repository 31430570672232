export namespace Permissions {
	export enum User {
		LIST = 'users:list',
		VIEW = 'users:view',
		CREATE = 'users:create',
		EDIT = 'users:edit',
		DELETE = 'users:delete',
	}

	export enum SpillReport {
		LIST = 'reports:list',
		VIEW = 'reports:view',
		CREATE = 'reports:create',
		EDIT = 'reports:edit',
		DELETE = 'reports:delete',
	}

	export enum PoliceReport {
		LIST = 'police:list',
		CREATE = 'police:create'
	}

	export enum RemediationReport {
		LIST = 'remediation:list',
		VIEW = 'remediation:view',
		CREATE = 'remediation:create',
		EDIT = 'remediation:edit',
		DELETE = 'remediation:delete',
	}

	export enum StatisticalReport {
		LIST = 'stats:list',
	}

	export enum Filter {
		LIST = 'filter:list',
	}

	export enum BulkActions {
		LIST = 'bulk:list'
	}

	export enum Company {
		LIST = 'companies:list',
		VIEW = 'companies:view',
		CREATE = 'companies:create',
		EDIT = 'companies:edit',
		DELETE = 'companies:delete',
	}

	export enum Collections {
		LIST = 'collections:list',
		VIEW = 'collections:view',
		CREATE = 'collections:create',
		EDIT = 'collections:edit',
		DELETE = 'collections:delete',
	}

	export enum Invoices {
		LIST = 'invoices:list',
		VIEW = 'invoices:view',
		CREATE = 'invoices:create',
		EDIT = 'invoices:edit',
		DELETE = 'invoices:delete',
	}

	export enum Deposits {
		LIST = 'deposits:list',
		VIEW = 'deposits:view',
		CREATE = 'deposits:create',
		EDIT = 'deposits:edit',
		DELETE = 'deposits:delete',
	}

	export enum Settings {		
		CREATE = 'settings:create',
		EDIT = 'settings:edit',
	}

	export enum InsuranceCompany {
		LIST = 'insurance:list',
		VIEW = 'insurance:view',
		CREATE = 'insurance:create',
		DELETE = 'insurance:delete',
	}
}

export enum Roles {
	SUPER_ADMIN = 'super_admin',
	ADMIN = 'admin',
	COMPANY_ADMIN = 'company_admin',
	DRIVER = 'driver',
	CLEANER = 'cleaner',
	BILLING_ADMIN = 'billing_admin',
	COLLECTION_ADMIN = 'collection_admin'
}
