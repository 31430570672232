<div class="row">
    <div class="col-12 mt-3 mb-3 text-center">
        <div>
            <i [class]="iconClass || 'fad fa-car fa-4x'"></i>
        </div>
        <h5 class="mt-4"><strong>No {{resource}} to display</strong></h5>
        <p>
            Looks like you do not have any {{resource}}.<br/>
            <span *ngIf="showSubtitle">Please start by adding {{resource}}.</span>
        </p>
    </div>
</div>
