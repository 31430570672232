import {DateOptionValue, defaultBoolOptions, FIELD_TYPES, FilterField, OptionValue} from "./filters";

export enum StatisticalReportType{
	CLEANER = 'cleaner',
	DEPOSITS = 'deposits'
}

export interface CleanerReportDto{
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	none: number;
	incomplete: number;
	cleaned: number;
	nsf: number;
	total: number;
}

export const CLEANER_REPORT_DATE_OPTIONS: DateOptionValue[] = [
	{
		label: 'Remediation Date',
		value: 'remediationDate',
		isPublic: true
	}
];

export const CLEANER_REPORT_FILTER_FIELDS: FilterField[] = [
	{label: 'State', property: 'incident_state', type: FIELD_TYPES.TEXT, isPublic: true},
];

export const DEPOSIT_REPORT_FILTER_FIELDS: FilterField[] = [
	{label: 'Texas Deposit', property: 'isTexasDeposit', type: FIELD_TYPES.BOOLEAN, isPublic: true, options: defaultBoolOptions},
];
